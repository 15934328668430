// init
export const init = () => ({
    type: 'INIT'
});
// login
export const setLoggedIn = (loggedIn) => ({
    type: 'LOGGED_IN',
    loggedIn: loggedIn,
});
// setLoginProvider
export const setLoginProvider = (loginProvider) => ({
    type: 'LOGIN_PROVIDER',
    loginProvider: loginProvider,
});
// setLoginToken
export const setLoginToken = (loginToken) => ({
    type: 'LOGIN_TOKEN',
    loginToken: loginToken,
});
// setHash
export const setHash = (hash) => ({
    type: 'HASH',
    hash: hash,
});
// setUsuario
export const setUsuario = (usuario) => ({
    type: 'USUARIO',
    usuario: usuario,
});
// setConfig
export const setConfig = (config) => ({
    type: 'CONFIG',
    config: config,
});
// setLoginForm
export const setLoginForm = (loginForm) => ({
    type: 'LOGIN_FORM',
    loginForm: loginForm,
});