import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { Colors } from 'styles';

const Boton = withStyles({
    root: {
        margin: '4px',
    }
})(Button);
const BotonInfo = withStyles({
    root: {
        background: `${Colors.INFO}`,
        margin: '4px',
        color: 'white',
        '&:hover': {
            background: `${Colors.INFO}`,
        }
    },
    label: {
        textTransform: 'uppercase',
    }
})(Button);
const BotonSuccess = withStyles({
    root: {
        background: `${Colors.SUCCESS}`,
        margin: '4px',
        color: 'white',
        '&:hover': {
            background: `${Colors.SUCCESS}`,
        }
    },
    label: {
        textTransform: 'uppercase',
    }
})(Button);
const BotonDefault = withStyles({
    root: {
        background: Colors.PRIMARY,
        margin: '4px',
        color: 'white',
        '&:hover': {
            background: Colors.PRIMARY,
        }
    },
    label: {
        textTransform: 'uppercase',
    }
})(Button);
const IconButtonSmall = withStyles({
    root: {
        height: 32,
        width: 32,
        fontSize: '16px'
    }
})(IconButton);
const IconButtonExtraSmall = withStyles({
    root: {
        height: 28,
        width: 28,
        margin: '0px',
        fontSize: '14px'
    }
})(IconButton);

export { Boton, BotonInfo, BotonSuccess, BotonDefault, IconButtonSmall, IconButtonExtraSmall};