import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { DispatchContext } from 'context';
// import { StateContext, DispatchContext } from 'context';
import './pedidos.css';
import { useSnackbar } from 'notistack';

import Divider from '@material-ui/core/Divider';
import { KeyboardDatePicker } from '@material-ui/pickers';

const EditarPedido = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    // const state = useContext(StateContext);
    // const [selectOptions, setSelectOptions] = useState([]);
    const [pedido, setPedido] = useState({
        ...props.pedido,
    });
    const [original, setOriginal] = useState({
        ...props.pedido,
    });
    const [errores, setErrores] = useState({});
    useEffect(() => {
        if (props.configPedidos && props.configPedidos.jerarquias && props.configPedidos.jerarquias.length > 0) {
            let options = [];
            for (let i in props.configPedidos.jerarquias) {
                options.push({ value: props.configPedidos.jerarquias[i].id, label: props.configPedidos.jerarquias[i].nombre });
            }
            // setSelectOptions(options);
        }
    }, []);
    useEffect(() => {
        console.log('pedido', pedido);
    }, [pedido]);
    const cerrar = () => {
        props.cancelar();
        props.buscarResultados(props.filtros);
    };
    // const handleChange = (event) => {
    //     const target = event.target;
    //     const value = target.type === 'checkbox' ? target.checked : target.value;
    //     const name = target.name;
    //     setPedido({ ...pedido, [name]: value });
    //     setErrores({ ...errores, [name]: false });
    // };
    const guardar = () => {
        let todoBien = true;
        let data = {};
        let errores = {};
        let cambioDatos = false;
        const { id, fecha } = pedido;
        //fecha
        if (fecha) {
            if (fecha !== original.fecha) {
                data.fecha = fecha;
                cambioDatos = true;
            }
            errores.fecha = false;
        } else {
            todoBien = false;
            errores.fecha = true;
        }

        console.log('todoBien', todoBien);
        console.log('data', data);
        setErrores(errores); console.log('errores', errores);
        if (todoBien && cambioDatos) {
            data.id = id;
            subirDatos(data);
        } else if (!cambioDatos) {
            enqueueSnackbar('No hay cambios para guardar', { variant: 'info' });
        }
    };
    const subirDatos = (data) => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('a/items/' + data.id),
            data: data,
            method: 'PUT'
        };
        Api.requestAxios(options, response => {
            Helper.log('edicionProducto response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                enqueueSnackbar(response.data.msg, { variant: 'success' });
                cerrar();
            }
        }, error => {
            Helper.log('error edicionProducto', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al subir nuevo producto', { variant: 'error' });
        });
    };
    const onChangeFecha = (date) => {
        
    };
    return (
        <div>
            <div className="w3-padding-top bs-bbox">
                <form className="w3-margin-bottom w3-padding" id="formCrear">
                    <div className="w3-row">
                        <div className="w3-col l4 w3-padding">
                            <p>Estás viendo los datos de un pedido</p>
                        </div>
                        <div className="w3-col l8 w3-padding bs-bbox">
                            <div className="w3-white bs-bbox w3-padding-large">
                                <KeyboardDatePicker
                                    cancelLabel="Cancelar"
                                    clearLabel="Limpiar"
                                    okLabel="Ok"
                                    clearable
                                    id="fecha-desde"
                                    label="Fecha desde"
                                    format="DD/MM/YYYY"
                                    value={pedido.fecha}
                                    onChange={onChangeFecha}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Cambiar fecha',
                                    }}
                                /><br />




                            </div>
                        </div>
                    </div>
                </form>
                <Divider />
                <div className="w3-row w3-white">
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                            type="button"
                            onClick={() => props.cancelar()}>Cancelar</button>
                    </div>
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                            type="button"
                            onClick={() => guardar()}>Guardar</button>
                    </div>
                </div>
            </div>
        </div >
    );
};
export default withRouter(EditarPedido);