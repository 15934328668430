import React from 'react';
//state
const StateContext = React.createContext({});
const StateProvider = StateContext.Provider;
const StateConsumer = StateContext.Consumer;
//dispatch
const DispatchContext = React.createContext({});
const DispatchProvider = DispatchContext.Provider;
const DispatchConsumer = DispatchContext.Consumer;
export {
    StateContext, StateProvider, StateConsumer,
    DispatchContext, DispatchProvider, DispatchConsumer
};