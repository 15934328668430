import React from 'react';
import './loading.css';
const Loading = (props) => {
    const color = (props.color) ? props.color : '#fff';
    const fondo = (props.fondo) ? props.fondo : 'rgba(0,0,0,0.2)';
    return (
        <div className="loading w3-display-container" style={{backgroundColor: fondo}}>
          <div className="lds-ring w3-display-middle">
            <div style={{borderColor: color + ' transparent transparent transparent'}}></div>
            <div style={{borderColor: color + ' transparent transparent transparent'}}></div>
            <div style={{borderColor: color + ' transparent transparent transparent'}}></div>
            <div style={{borderColor: color + ' transparent transparent transparent'}}></div>
          </div>
        </div>
    );
};
export default Loading;