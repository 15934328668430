import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import Select from 'react-select';
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './jerarquias.css';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { Colors } from 'styles';

import { useDropzone } from 'react-dropzone';

function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const EditarJerarquia = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [selectOptions, setSelectOptions] = useState([]);
    const [jerarquia, setJerarquia] = useState({ ...props.jerarquia });
    const [errores, setErrores] = useState({});
    const [fotoFile, setFotoFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [original, setOriginal] = useState({ ...props.jerarquia });
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setFotoFile(acceptedFiles[0]);
            setJerarquia({ ...jerarquia, multimediaId: 'cambio' });
            setErrores({ ...errores, foto: false });
            console.log('acceptedFiles[0]', acceptedFiles[0]);
        }
    });
    useEffect(() => {
        if (props.configJerarquias && props.configJerarquias.jerarquias && props.configJerarquias.jerarquias.length > 0) {
            let options = [];
            options.push({ value: '', label: 'Ninguna' });
            for (let i in props.configJerarquias.jerarquias) {
                options.push({ value: props.configJerarquias.jerarquias[i].id, label: props.configJerarquias.jerarquias[i].nombre });
            }
            setSelectOptions(options);
        }
        let jqia = { ...jerarquia};
        let ori = { ...original};
        if (jerarquia && jerarquia.multimedia && jerarquia.multimedia.url && jerarquia.multimedia.id) {
            jqia.multimediaId = jerarquia.multimedia.id;
            ori.multimediaId = original.multimedia.id;
        }
        if (jerarquia.jerarquiaPapa && jerarquia.jerarquiaPapa.id) {
            jqia.jerarquiaPapaId = { value: jerarquia.jerarquiaPapa.id, label: jerarquia.jerarquiaPapa.nombre };
            ori.jerarquiaPapaId = { value: jerarquia.jerarquiaPapa.id, label: jerarquia.jerarquiaPapa.nombre };
        }
        setJerarquia(jqia);
        setOriginal(ori);
        setFile(jerarquia.multimedia.url);
    }, []);
    useEffect(() => {
        console.log('jerarquia', jerarquia);
    }, [jerarquia]);
    const setFile = async (url) => {
        const name = url.split('\\').pop().split('/').pop();
        const type = 'image/' + name.split('.').pop();
        let file = await fetch(url)
            .then(r => r.blob())
            .then(blobFile => new File([blobFile], name, { type: type }));
        setFotoFile(file);
        setFiles([Object.assign(file, {
            preview: Api.route(url)
        })]);
        setErrores({ ...errores, foto: false });
        console.log('file', file);
    };
    const deshacerCambioFoto = () => {
        if (original && original.multimedia && original.multimedia.url && original.multimedia.id) {
            setJerarquia({ ...jerarquia, multimediaId: original.multimedia.id });
            setFile(original.multimedia.url);
        }
    };
    const cerrar = () => {
        props.cancelar();
        props.buscarResultados(props.filtros);
    };
    const handleChangePapaId = (selected) => {
        setJerarquia({ ...jerarquia, jerarquiaPapaId: selected });
    };
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setJerarquia({ ...jerarquia, [name]: value });
        setErrores({ ...errores, [name]: false });
    };
    const guardar = () => {
        let todoBien = true;
        let data = {};
        let errores = {};
        let cambioDatos = false;
        let cambioFoto = false;
        const { id, orden, nombre, multimediaId, jerarquiaPapaId } = jerarquia; console.log('jerarquia', jerarquia);console.log('original', original);
        //orden
        if (orden) {
            if (orden !== original.orden) {
                data.orden = orden;
                cambioDatos = true;
            }
            errores.orden = false;
        } else {
            todoBien = false;
            errores.orden = true;
        }
        //nombre
        if (nombre) {
            if (nombre !== original.nombre) {
                data.nombre = nombre;
                cambioDatos = true;
            }
            errores.nombre = false;
        } else {
            todoBien = false;
            errores.nombre = true;
        }
        //multimediaId
        if (multimediaId) {
            if (multimediaId !== original.multimedia.id) {
                cambioFoto = true;
            }
            errores.multimediaId = false;
        } else {
            todoBien = false;
            errores.multimediaId = true;
        }
        //jerarquiaPapaId
        if (jerarquiaPapaId && original.jerarquiaPapaId) {
            if (jerarquiaPapaId.value !== original.jerarquiaPapaId.value) {
                if (jerarquiaPapaId.value === '') {
                    data.jerarquiaPapaId = null;
                } else {
                    data.jerarquiaPapaId = jerarquiaPapaId.value;
                }                
                cambioDatos = true;
            }
            errores.jerarquiaPapaId = false;
        } else if (jerarquiaPapaId) {
            if (jerarquiaPapaId.value === '') {
                data.jerarquiaPapaId = null;
            } else {
                data.jerarquiaPapaId = jerarquiaPapaId.value;
            }
            cambioDatos = true;
            errores.jerarquiaPapaId = false;
        } else {
            // todoBien = false;
            // errores.jerarquiaPapaId = true;
        }
        setErrores(errores); console.log('errores', errores);
        if (todoBien) {
            if (cambioDatos && cambioFoto) {
                data.id = id;
                subirFoto(data);
            } else if (cambioDatos) {
                data.id = id;
                subirDatos(data);
            } else if (cambioFoto) {
                data.id = id;
                subirFoto(data);
            }
        }
    };
    const subirFoto = (data) => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var ruta = Api.route('a/multimedia');
        var clave = 'foto';
        var params = {};
        Api.uploadImageAxios(ruta, clave, fotoFile, params, function (response) {
            Helper.log('uploadFoto response', response);
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                data.multimediaId = response.data.datos.id;
                subirDatos(data);
            }
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
        }, function (error) {
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al subir la foto', { variant: 'error' });
            Helper.log('error uploadFoto', error);
        });
    }
    const subirDatos = (data) => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('a/jerarquias/' + data.id),
            data: data,
            method: 'PUT'
        };
        Api.requestAxios(options, response => {
            Helper.log('edicionJerarquia response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                enqueueSnackbar(response.data.msg, { variant: 'success' });
                cerrar();
            }
        }, error => {
            Helper.log('error edicionJerarquia', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al subir nueva jerarquía', { variant: 'error' });
        });
    };
    return (
        <div>
            <div className="w3-padding-top bs-bbox">
                <form className="w3-margin-bottom w3-padding" id="formCrear">
                    <div className="w3-row">
                        <div className="w3-col l4 w3-padding">
                            <p>Estás viendo los datos de un jerarquia</p>
                        </div>
                        <div className="w3-col l8 w3-padding bs-bbox">
                            <div className="w3-white bs-bbox w3-padding-large">
                                <div className="w3-block w3-border w3-padding w3-round-small">
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        <FontAwesomeIcon icon={faCloudUploadAlt} size="lg" />
                                        <p>Arrastrar o subir imagen</p>
                                    </div>
                                    {/* <aside className="thumbs-container">
                                        {files.map(file => (<div className="thumb-box" key={file.name}>
                                            <div className="thumb-inner">
                                                <img src={file.preview} alt="thumb" style={{
                                                    display: 'block',
                                                    width: 'auto',
                                                    height: '100%'
                                                }} />
                                            </div>
                                        </div>))}
                                    </aside> */}
                                    <aside className="w3-block w3-margin-top w3-margin-bottom">
                                        {files.map(file => (<div className="thumb-container-large w3-gray" key={file.name}
                                            style={{
                                                backgroundImage: "url('" + file.preview + "')"
                                            }}>
                                        </div>))}
                                    </aside>
                                    {(original.multimediaId && (original.multimediaId !== jerarquia.multimediaId)) ? <Button color="secondary" variant="contained" onClick={() => deshacerCambioFoto()}>Deshacer cambio de foto</Button> : null}
                                    {errores.foto ? <span style={{ color: Colors.ALERT }}>&nbsp;Foto es requerida</span> : null}
                                </div>
                                <br />
                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.orden}
                                    name="orden"
                                    type="text"
                                    label="Orden"
                                    value={jerarquia.orden}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br />

                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.nombre}
                                    name="nombre"
                                    type="text"
                                    label="Nombre"
                                    value={jerarquia.nombre}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br />


                                {selectOptions.length > 0 ? <>
                                    <label>Categoría padre</label>
                                    <Select
                                        className={(errores.jerarquiaPapaId ? "w3-border w3-border-red" : "")}
                                        name="jerarquiaPapaId"
                                        value={jerarquia.jerarquiaPapaId}
                                        onChange={handleChangePapaId}
                                        options={selectOptions}
                                        placeholder="Categoría padre"
                                        isDisabled={state.appReducer.actionsDisabled} />
                                </> : null}

                            </div>
                        </div>
                    </div>
                </form>
                <Divider />
                <div className="w3-row w3-white">
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                            type="button"
                            onClick={() => props.cancelar()}>Cancelar</button>
                    </div>
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                            type="button"
                            onClick={() => guardar()}>Guardar</button>
                    </div>
                </div>
            </div>
        </div >
    );
};
export default withRouter(EditarJerarquia);