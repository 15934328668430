import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import './sidenav.css';
import IconButton from '@material-ui/core/IconButton';
const PanelContainer = (props) => {
    const { children, closeLabel, panelLabel } = props;
    return (
        <div style={{ backgroundColor: '#F7F7F7' }}>
            <div className="">
                <div className="w3-row">
                    <div className="w3-col l12 bs-bbox">
                        <div className="w3-cell">
                            <IconButton aria-label="delete" onClick={() => props.close()}>
                                &nbsp;<FontAwesomeIcon icon={faTimes}/>&nbsp;
                            </IconButton>
                            {/* <button className="w3-btn w3-transparent w3-text-gray w3-margin-right w3-xxlarge"
                                onClick={() => props.close()}><FontAwesomeIcon icon={faTimes} /></button> */}
                        </div>
                        <div className="w3-cell w3-padding">
                            <span className="w3-large">&nbsp;{panelLabel}</span>
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
};
export default PanelContainer;