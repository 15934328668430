// Initial State
const appInitialState = {
    actionsDisabled: false,
    loading: false,
    lastVisited: 'login',
    menuAbierto: false,
    itemsCarrito: {},
    paginaActual: null,
    sideMenuToggle: {},
    modificaciones: null,
    filtrosProductos: null
};
// Reducers (Modifies The State And Returns A New State)
const appReducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            return {
                ...appInitialState
            };
        }
        case 'ACTIONS_DISABLED': {
            return {
                ...state,
                actionsDisabled: action.actionsDisabled,
            }
        }
        case 'LOADING': {
            return {
                ...state,
                loading: action.loading,
            }
        }
        case 'LAST_VISITED': {
            return {
                ...state,
                lastVisited: action.lastVisited,
            }
        }
        case 'MENU_ABIERTO': {
            return {
                ...state,
                menuAbierto: action.menuAbierto,
            }
        }
        case 'ITEMS_CARRITO': {
            return {
                ...state,
                itemsCarrito: action.itemsCarrito
            }
        }
        case 'PAGINA_ACTUAL': {
            return {
                ...state,
                paginaActual: action.paginaActual
            }
        }
        case 'SIDE_MENU_TOGGLE': {
            return {
                ...state,
                sideMenuToggle: action.sideMenuToggle
            }
        }
        case 'MODIFICACIONES': {
            return {
                ...state,
                modificaciones: action.modificaciones
            }
        }
        case 'FILTROS_PRODUCTOS': {
            return {
                ...state,
                filtrosProductos: action.filtrosProductos
            }
        }

        default: {
            return state;
        }
    }
};
// Exports
export { appReducer, appInitialState };