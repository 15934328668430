import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import Select from 'react-select';
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

import './jerarquias.css';
import { useSnackbar } from 'notistack';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { Colors } from 'styles';

import { useDropzone } from 'react-dropzone';

// function readFile(file) {
//     return new Promise(resolve => {
//         const reader = new FileReader()
//         reader.addEventListener('load', () => resolve(reader.result), false)
//         reader.readAsDataURL(file)
//     })
// }
const CrearJerarquia = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [selectOptions, setSelectOptions] = useState([]);
    const [jerarquia, setJerarquia] = useState({
        abm: 'nuevo',
        nombre: '',
        orden: '',
        fotoId: '',
        jerarquiaPapaId: ''
    });
    const [errores, setErrores] = useState({});
    const [fotoFile, setFotoFile] = useState(null);
    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setFotoFile(acceptedFiles[0]);
            setErrores({ ...errores, foto: false });
            console.log('acceptedFiles[0]', acceptedFiles[0]);
        }
    });
    useEffect(() => {
        if (props.configJerarquias && props.configJerarquias.jerarquias && props.configJerarquias.jerarquias.length > 0) {
            let options = [];
            for (let i in props.configJerarquias.jerarquias) {
                options.push({ value: props.configJerarquias.jerarquias[i].id, label: props.configJerarquias.jerarquias[i].nombre });
            }
            setSelectOptions(options);
        }
    }, []);
    // const setFile = async (url) => {
    //     let file = await fetch(url)
    //         .then(r => r.blob())
    //         .then(blobFile => new File([blobFile], fotoFile.name, { type: fotoFile.type }));
    //     setFotoFile(file);
    //     let imageDataUrl = await readFile(file);
    //     console.log('imageDataUrl', imageDataUrl);
    //     setErrores({ ...errores, foto: false });
    // };

    const cerrar = () => {
        props.cancelar();
        props.buscarResultados(props.filtros);
    };
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setJerarquia({ ...jerarquia, [name]: value });
        setErrores({ ...errores, [name]: false });
    };
    const handleChangePapaId = (selected) => {
        setJerarquia({ ...jerarquia, jerarquiaPapaId: selected });
    };
    const guardar = () => {
        let todoBien = true;
        let data = {};
        let errores = {};
        const { nombre, orden, jerarquiaPapaId } = jerarquia;
        //nombre
        if (nombre) {
            data.nombre = nombre;
            errores.nombre = false;
        } else {
            todoBien = false;
            errores.nombre = true;
        }
        //orden
        if (orden) {
            data.orden = orden;
            errores.orden = false;
        } else {
            todoBien = false;
            errores.orden = true;
        }
        //tipo
        if (jerarquiaPapaId && jerarquiaPapaId.value) {
            data.jerarquiaPapaId = jerarquiaPapaId.value;
            errores.jerarquiaPapaId = false;
        } else {
            // todoBien = false;
            // errores.jerarquiaPapaId = true;
        }
        //fotoFile
        if (fotoFile) {
            errores.foto = false;
        } else {
            todoBien = false;
            errores.foto = true;
        }
        setErrores(errores);
        console.log('todoBien', todoBien);
        console.log('data', data);
        if (todoBien) {
            if (fotoFile) {
                subirFoto(data);
            } else {
                subirDatos(data);
            }
        }
    };
    const subirFoto = (data) => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var ruta = Api.route('a/multimedia');
        var clave = 'foto';
        var params = {};
        Api.uploadImageAxios(ruta, clave, fotoFile, params, function (response) {
            Helper.log('uploadFoto response', response);
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                data.multimediaId = response.data.datos.id;
                subirDatos(data);
            }
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
        }, function (error) {
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al subir la foto', { variant: 'error' });
            Helper.log('error uploadFoto', error);
        });
    }
    const subirDatos = (data) => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('a/jerarquias'),
            data: data,
            method: 'POST'
        };
        Api.requestAxios(options, response => {
            Helper.log('nuevaJerarquia response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                enqueueSnackbar(response.data.msg, { variant: 'success' });
                cerrar();
            }
        }, error => {
            Helper.log('error nuevaJerarquia', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al subir nueva jerarquía', { variant: 'error' });
        });
    };
    return (
        <div>
            <div className="w3-padding-top bs-bbox">
                <form className="w3-margin-bottom w3-padding">
                    <div className="w3-row">
                        <div className="w3-col l4 w3-padding">
                            <p>Completa los datos de la categoría</p>
                        </div>
                        <div className="w3-col l8 w3-padding bs-bbox">
                            <div className="w3-white bs-bbox w3-padding-large">
                                <div className="w3-block w3-border w3-padding w3-round-small">
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        <FontAwesomeIcon icon={faCloudUploadAlt} size="lg" />
                                        <p>Arrastrar o subir imagen</p>
                                    </div>
                                    <aside className="thumbs-container">
                                        {files.map(file => (<div className="thumb-box" key={file.name}>
                                            <div className="thumb-inner">
                                                <img src={file.preview} alt="thumb" style={{
                                                    display: 'block',
                                                    width: 'auto',
                                                    height: '100%'
                                                }} />
                                            </div>
                                        </div>))}
                                    </aside>
                                    {errores.foto ? <span style={{ color: Colors.ALERT }}>&nbsp;Foto es requerida</span> : null}
                                </div>
                                <br />
                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.orden}
                                    name="orden"
                                    type="text"
                                    label="Orden"
                                    value={jerarquia.orden}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br />

                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.nombre}
                                    name="nombre"
                                    type="text"
                                    label="Nombre"
                                    value={jerarquia.nombre}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br />

                                {selectOptions.length > 0 ? <>
                                    <label>Categoría padre</label>
                                    <Select
                                        className={(errores.jerarquiaPapaId ? "w3-border w3-border-red" : "")}
                                        name="jerarquiaPapaId"
                                        value={jerarquia.jerarquiaPapaId}
                                        onChange={handleChangePapaId}
                                        options={selectOptions}
                                        placeholder="Jerarquía padre"
                                        isDisabled={state.appReducer.actionsDisabled} />
                                </> : null}
                            </div>
                        </div>
                    </div>
                </form>
                <Divider />
                <div className="w3-row w3-white">
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                            type="button"
                            onClick={() => props.cancelar()}>Cancelar</button>
                    </div>
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                            type="button"
                            onClick={() => guardar()}>Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(CrearJerarquia);