import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import Select from 'react-select';
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faFilePdf, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import './productos.css';
import { useSnackbar } from 'notistack';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { Colors } from 'styles';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';

import { useDropzone } from 'react-dropzone';

function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}
const CrearProducto = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [selectOptions, setSelectOptions] = useState([]);
    const [producto, setProducto] = useState({
        abm: 'nuevo',
        nombre: '',
		codigo: '',
        descripcion: EditorState.createEmpty(),
        orden: '',
        // especificaciones: EditorState.createEmpty(),
        destacado: false,
        precio: '',
        cantidad: '',
        activo: false,
        fichaTecnica: null,
        fichaTecnicaId: null,
        jerarquiasIds: [],
        multimediaIds: []
    });
    const [errores, setErrores] = useState({});
    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            // setFiles([...files, ...acceptedFiles.map(file => Object.assign(file, {
            //     preview: URL.createObjectURL(file)
            // }))]);
            subirFotos(acceptedFiles, 0, [...producto.multimediaIds]);
            setErrores({ ...errores, multimediaIds: false });
            console.log('acceptedFiles', acceptedFiles);
        }
    });
    useEffect(() => {
        if (props.configProductos && props.configProductos.jerarquias && props.configProductos.jerarquias.length > 0) {
            let options = [];
            for (let i in props.configProductos.jerarquias) {
                options.push({ value: props.configProductos.jerarquias[i].id, label: props.configProductos.jerarquias[i].nombre });
            }
            setSelectOptions(options);
        }
    }, []);
    useEffect(() => {
        console.log('files', files);
    }, [files]);
    useEffect(() => {
        console.log('producto', producto);
    }, [producto]);
    // const setFile = async (url) => {
    //     let file = await fetch(url)
    //         .then(r => r.blob())
    //         .then(blobFile => new File([blobFile], fotoFile.name, { type: fotoFile.type }));
    //     setFotoFile(file);
    //     let imageDataUrl = await readFile(file);
    //     console.log('imageDataUrl', imageDataUrl);
    //     setErrores({ ...errores, foto: false });
    // };
    const descartarImagen = (index) => {
        if (files[index].multimediaId && files[index].multimediaId === producto.multimediaIds[index]) {
            let mtIds = [...producto.multimediaIds];
            mtIds.splice(index, 1);
            setProducto({ ...producto, multimediaIds: mtIds });
        }
        let fs = [...files];
        fs.splice(index, 1);
        setFiles(fs);
    }
    const cerrar = () => {
        props.cancelar();
        props.buscarResultados(props.filtros);
    };
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setProducto({ ...producto, [name]: value });
        setErrores({ ...errores, [name]: false });
    };
    const handleChangeHtml = (newValue, name) => {
        setProducto({ ...producto, [name]: newValue, [name + 'Html']: stateToHTML(newValue.getCurrentContent()) });
        setErrores({ ...errores, [name]: false });
    };
    const handleChangeJerarquiaId = (selecteds) => {
        setProducto({ ...producto, jerarquiasIds: selecteds });
    };
    const onChangeFichaTecnica = event => {
        subirPdf(event.target.files[0]);
    };
    const guardar = () => {
        let todoBien = true;
        let data = {};
        let errores = {};
        // const { nombre, orden, descripcionHtml, especificacionesHtml, fichaTecnicaId, destacado, precio, cantidad, activo, multimediaIds, jerarquiasIds } = producto;
        const { nombre, codigo, orden, descripcionHtml, fichaTecnicaId, destacado, precio, cantidad, activo, multimediaIds, jerarquiasIds } = producto;
        //nombre
        if (nombre) {
            data.nombre = nombre;
            errores.nombre = false;
        } else {
            todoBien = false;
            errores.nombre = true;
        }
		if (codigo) {
            data.codigo = codigo;
            errores.codigo = false;
        }
        //orden
        data.orden = orden === '' ? 0 : orden;
        errores.orden = false;

        //descripcionHtml
        if (descripcionHtml) {
            data.descripcion = descripcionHtml;
            errores.descripcion = false;
        } else {
            todoBien = false;
            errores.descripcion = true;
        }
        //especificacionesHtml
        // if (especificacionesHtml) {
        //     data.especificaciones = especificacionesHtml;
        //     errores.especificaciones = false;
        // } else {
        //     todoBien = false;
        //     errores.especificaciones = true;
        // }
        //fichaTecnicaId
        if (fichaTecnicaId) {
            data.fichaTecnicaId = fichaTecnicaId;
            errores.fichaTecnicaId = false;
        } else {
            // todoBien = false;
            // errores.fichaTecnicaId = true;
        }
        //destacado
        if (destacado) {
            data.destacado = true;
            errores.destacado = false;
        } else {
            data.destacado = false;
            errores.destacado = false;
        }
        //precio
        if (precio) {
            data.precio = precio;
            errores.precio = false;
        } else {
            todoBien = false;
            errores.precio = true;
        }
        //cantidad
        if (cantidad) {
            data.cantidad = cantidad;
            errores.cantidad = false;
        } else {
            todoBien = false;
            errores.cantidad = true;
        }
        //activo
        if (activo) {
            data.activo = true;
            errores.activo = false;
        } else {
            data.activo = false;
            errores.activo = false;
        }
        //jerarquiaId
        if (jerarquiasIds && jerarquiasIds.length > 0) {
            data.jerarquiasIds = [];
            for (let i in jerarquiasIds) {
                data.jerarquiasIds.push(jerarquiasIds[i].value);
            }
            errores.jerarquiasIds = false;
        } else {
            // todoBien = false;
            // errores.jerarquiasIds = true;
        }
        //multimediaIds
        if (multimediaIds && multimediaIds.length > 0) {
            data.multimediaIds = multimediaIds;
            errores.multimediaIds = false;
        } else {
            todoBien = false;
            errores.multimediaIds = true;
        }
        setErrores(errores);
        console.log('todoBien', todoBien);
        console.log('errores', errores);
        console.log('data', data);
        if (todoBien) {
            subirDatos(data);
        }
    };
    const subirFotos = (archivos, indice, multimediaIds) => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var ruta = Api.route('a/multimedia');
        var clave = 'foto';
        var params = {};
        const ultima = archivos.length === indice + 1;
        Api.uploadImageAxios(ruta, clave, archivos[indice], params, function (response) {
            Helper.log('uploadFoto response', response);
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
                archivos.splice(indice, 1);
                if (ultima) {
                    setFiles([...files, ...archivos]);
                }
                if (archivos.length > (indice)) {
                    subirFotos(archivos, indice, multimediaIds);
                }
            } else {
                archivos[indice].multimediaId = response.data.datos.id;
                archivos[indice].preview = URL.createObjectURL(archivos[indice]);
                if (ultima) {
                    setFiles([...files, ...archivos]);
                }
                multimediaIds.push(response.data.datos.id);
                setProducto({ ...producto, multimediaIds: multimediaIds });
                if (archivos.length > (indice + 1)) {
                    subirFotos(archivos, indice + 1, multimediaIds);
                }
            }
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
        }, function (error) {
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al subir la foto', { variant: 'error' });
            Helper.log('error uploadFoto', error);
        });
    }
    const subirDatos = (data) => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('a/items'),
            data: data,
            method: 'POST'
        };
        Api.requestAxios(options, response => {
            Helper.log('nuevoProducto response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                enqueueSnackbar(response.data.msg, { variant: 'success' });
                cerrar();
            }
        }, error => {
            Helper.log('error nuevoProducto', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al subir nuevo producto', { variant: 'error' });
        });
    };
    const subirPdf = (archivo) => { console.log('archivo', archivo);
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var ruta = Api.route('a/documentos');
        var clave = 'documento';
        var params = {};
        Api.uploadFileAxios(ruta, clave, archivo, params, function (response) {
            Helper.log('uploadFoto response', response);
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });                
            } else {
                setProducto({ ...producto, fichaTecnica: response.data.datos, fichaTecnicaId: response.data.datos.id });
            }
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
        }, function (error) {
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al subir documento', { variant: 'error' });
        });
    }
    return (
        <div id="crear-producto">
            <div className="w3-padding-top bs-bbox">
                <form className="w3-margin-bottom w3-padding">
                    <div className="w3-row">
                        <div className="w3-col l4 w3-padding">
                            <p>Completa los datos del producto</p>
                        </div>
                        <div className="w3-col l8 w3-padding bs-bbox">
                            <div className="w3-white bs-bbox w3-padding-large">
                                <div className={"w3-block w3-border w3-padding w3-round-small" + ((errores.multimediaIds) ? " w3-border-red" : "")}>
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        <FontAwesomeIcon icon={faCloudUploadAlt} size="lg" />
                                        <p>Arrastrar o subir imagen</p>
                                    </div>
                                    <div className="w3-row w3-margin-top">
                                        {files.map((file, index) => (<div key={file.name} className="w3-col bs-bbox" style={{ width: '128px', padding: '4px' }}>
                                            <div className="w3-border w3-round" style={{
                                                width: '100%',
                                                paddingTop: '100%',
                                                backgroundColor: '#F4F6F8',
                                                position: 'relative',
                                                backgroundPosition: 'center',
                                                backgroundSize: 'contain',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundImage: "url('" + file.preview + "')"
                                            }}>
                                                <IconButton style={{ position: 'absolute', top: 0, right: 0, padding: '2px' }} aria-label="delete" onClick={() => descartarImagen(index)}>
                                                    &nbsp;<FontAwesomeIcon icon={faTimesCircle} />&nbsp;
                                                </IconButton>
                                            </div>
                                        </div>))}
                                    </div>
                                    {errores.multimediaIds ? <span style={{ color: Colors.ALERT }}>&nbsp;Foto es requerida</span> : null}
                                </div>
                                <br />
                                <TextField fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.orden}
                                    name="orden"
                                    type="number"
                                    label="Orden en el listado"
                                    value={producto.orden}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled}
								/>
								<br />
                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.nombre}
                                    name="nombre"
                                    type="text"
                                    label="Nombre"
                                    value={producto.nombre}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled}
								/>
								<br />
								<TextField fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.codigo}
                                    name="codigo"
                                    type="text"
                                    label="Código"
                                    value={producto.codigo}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled}
								/>
								<br />

                                <span>Descripción</span>
                                <Editor
                                    toolbar={{ options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'] }}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    editorState={producto.descripcion}
                                    onEditorStateChange={(newValue) => handleChangeHtml(newValue, 'descripcion')}
                                /><br /><br />

                                {/* <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    multiline
                                    rows={4}
                                    error={errores.descripcion}
                                    name="descripcion"
                                    type="text"
                                    label="Descripción"
                                    value={producto.descripcion}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /> */}

                                {/* <span>Especificaciones</span>
                                <Editor
                                    toolbar={{ options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'] }}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    editorState={producto.especificaciones}
                                    onEditorStateChange={(newValue) => handleChangeHtml(newValue, 'especificaciones')}
                                /><br /><br /> */}

                                {/* <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    multiline
                                    rows={5}
                                    error={errores.especificaciones}
                                    name="especificaciones"
                                    type="text"
                                    label="Especificaciones"
                                    value={producto.especificaciones}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /> */}

                                
								<span>Ficha técnica</span><br />
                                { (producto && producto.fichaTecnica) ? <a href={Api.route(producto.fichaTecnica.url)} target="_blank">
                                <div style={{padding: '8px 0px', color: Colors.THEME}}>
                                    <FontAwesomeIcon icon={faFilePdf} size="lg" />
                                    <span> Ficha técnica.pdf</span>
                                    </div>
                                </a> : null }
                                <input
                                    accept="application/pdf"
                                    style={{ display: 'none' }}
                                    id="input-ficha-tecnica"
                                    multiple
                                    onChange={onChangeFichaTecnica}
                                    type="file"
                                />
                                <label htmlFor="input-ficha-tecnica">
                                    <Button variant="contained" color="primary" component="span">
                                        <span>&nbsp;Seleccionar PDF</span>
                                    </Button>
                                </label>
                                <br /><br />
								

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={producto.destacado}
                                            onChange={handleChange}
                                            name="destacado"
                                            color="primary"
                                        />
                                    }
                                    label="Destacado"
                                /><br />

                                <div className="w3-row">
                                    <div className="w3-col l6 m6 s12 bs-bbox" style={{ paddingRight: '4px' }}>
                                        <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                            error={errores.precio}
                                            name="precio"
                                            type="number"
                                            label="Precio"
                                            value={producto.precio}
                                            onChange={handleChange}
                                            disabled={state.appReducer.actionsDisabled} />
                                    </div>
                                    <div className="w3-col l6 m6 s12">
                                        <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                            error={errores.cantidad}
                                            name="cantidad"
                                            type="number"
                                            label="Cantidad"
                                            value={producto.cantidad}
                                            onChange={handleChange}
                                            disabled={state.appReducer.actionsDisabled} />
                                    </div>
                                </div>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={producto.activo}
                                            onChange={handleChange}
                                            name="activo"
                                            color="primary"
                                        />
                                    }
                                    label="Activo"
                                /><br />

                                {selectOptions.length > 0 ? <>
                                    <label>Categorías</label>
                                    <Select
                                        isMulti
                                        classNamePrefix="select"
                                        className={(errores.jerarquiasIds ? "basic-multi-select w3-border w3-border-red" : "basic-multi-select")}
                                        name="jerarquiasIds"
                                        value={producto.jerarquiasIds}
                                        onChange={handleChangeJerarquiaId}
                                        options={selectOptions}
                                        placeholder="Categorías"
                                        isDisabled={state.appReducer.actionsDisabled} />
                                </> : null}
                            </div>
                        </div>
                    </div>
                </form>
                <Divider />
                <div className="w3-row w3-white">
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                            type="button"
                            onClick={() => props.cancelar()}>Cancelar</button>
                    </div>
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                            type="button"
                            onClick={() => guardar()}>Guardar</button>
                    </div>
                </div>
            </div>
        </div >
    );
};
export default withRouter(CrearProducto);