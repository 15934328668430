import React, { useState, useContext, useEffect } from 'react';
import CONFIG from "config";
import { Helper } from 'utils';
import { Api } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import SlidingPanel from 'react-sliding-side-panel';
import VerAdministrador from './ver-administrador';
import CrearAdministrador from './crear-administrador';
import EditarAdministrador from './editar-administrador';
import { PanelContainer } from 'components/sidenav';
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './administradores.css';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';

import { useWindowSize } from 'hooks';
import { Colors } from 'styles';
import { useSnackbar } from 'notistack';


const OutlinedInputSmall = withStyles({
    root: {
        height: 45,
        backgroundColor: Colors.WHITE
    }
})(OutlinedInput);

const BootstrapInput = withStyles((theme) => ({
    input: {
        fontSize: 16,
        padding: '10px 26px 10px 12px',
    },
}))(InputBase);

const BotonAccion = withStyles({
    root: {
        margin: '4px'
    }
})(Button);

const BotonAccionInfo = withStyles({
    root: {
        background: `${Colors.INFO}`,
        margin: '4px',
        color: 'white',
        '&:hover': {
            background: `${Colors.INFO}`,
        }
    },
    label: {
        textTransform: 'uppercase',
    }
})(Button);


const Administradores = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [administradores, setAdministradores] = useState([]);
    const [administradorId, setAdministradorId] = useState(null);
    const [tiposUsuarios, setTiposUsuarios] = useState(null);
    const [tiposUsuariosId, setTiposUsuariosId] = useState(null);
    const [optionsSelectTipo, setOptionsSelectTipo] = useState([{ value: 3, label: 'Administrador' }]);
    const [searchText, setSearchText] = useState('');
    const [modulos, setModulos] = useState(null);
    const [panelCrear, setPanelCrear] = useState(false);
    const [panelVer, setPanelVer] = useState(false);
    const [panelEditar, setPanelEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [administrador, setAdministrador] = useState(null);
    const [paginador, setPaginador] = useState({ numResultados: 0 });
    const windowSize = useWindowSize();
    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Administradores'; console.log('state.authReducer.usuario', state.authReducer.usuario);
        if (state.authReducer.usuario && state.authReducer.usuario.id) {
            setAdministradorId(state.authReducer.usuario.id);
            getAdministradores();
        } else {
            const { history } = props;
            if (history) history.push('/login');
        }
    }, []);
    const slideSize = (wz) => {
        if (wz.width < 1180) {
            return 100;
        } else {
            return Math.floor((1180 * 100) / wz.width);
        }
    }
    const onChangeSearchText = (e) => {
        setSearchText(e.target.value);
    }
    const getAdministradores = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('a/ver-usuarios'),
            method: 'POST'
        };
        Api.requestAxios(options, response => {
            Helper.log('getAdministradores response', response);
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                setAdministradores(response.data.datos.usuarios);
                setPaginador({ ...paginador, numResultados: response.data.datos.numResultados });
            }
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
        }, error => {
            Helper.log('error getAdministradores', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer administradores', { variant: 'error' });
        });
    }
    //VER
    const ver = (admr) => {
        setAdministrador({ ...admr, abm: 'ver' });
        setPanelVer(true);
    }
    //CREAR
    const crear = () => {
        setPanelCrear(true);
    }
    //EDITAR
    const editar = (admr) => {
        setAdministrador({ ...admr, abm: 'editar' });
        setPanelEditar(true);
    }
    //ELIMINAR
    const eliminar = (admr) => {
        setAdministrador({ ...admr, abm: 'eliminar' });
        setModalEliminar(true);
    }
    const confirmarEliminar = () => {
        var options = {
            url: Api.route('a/usuarios/' + administrador.id),
            method: 'DELETE'
        };
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        Api.requestAxios(options, response => {
            Helper.log('deleteAdministrador response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                enqueueSnackbar(response.data.msg, { variant: 'success' });
                getAdministradores();
                cancelar();
            }
        }, error => {
            Helper.log('error deleteAdministrador', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al eliminar administrador', { variant: 'error' });
        });
    }
    //CANCELAR
    const cancelar = () => {
        setAdministrador(null);
        setPanelVer(false);
        setPanelCrear(false);
        setPanelEditar(false);
        setModalEliminar(false);
    }
    const clickOpcion = (opcion, data, index) => {
        let admtdrs = [...administradores];
        admtdrs[index].anchorEl = null;
        setAdministradores(admtdrs);
        switch (opcion) {
            case 'ver':
                ver(data);
                break;
            case 'editar':
                editar(data);
                break;
            case 'eliminar':
                eliminar(data);
                break;
            default:
                break;
        }
    }
    const onClickOpciones = (event, index) => {
        let admtdrs = [...administradores];
        admtdrs[index].anchorEl = event.currentTarget;
        setAdministradores(admtdrs);
    }
    return (
        <div>
            <div className="w3-row">
                <div className="w3-col w3-right w3-right-align" style={{ width: '300px' }}>
                    <Button onClick={() => crear()} color="primary" variant="contained">Agregar administrador</Button>
                </div>
                <div className="w3-rest">
                    <h3>Administradores</h3>
                </div>
            </div>
            <div className="w3-block">
                <FormControl variant="outlined" style={{ width: '100%', maxWidth: '420px', }}>
                    <InputLabel></InputLabel>
                    <OutlinedInputSmall
                        placeholder="Buscar administrador"
                        id="input-cantidad"
                        type="text"
                        fullWidth={true}
                        value={searchText}
                        onChange={(e) => onChangeSearchText(e)}
                        disabled={(state.appReducer.actionsDisabled) ? true : false}
                        startAdornment={
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon={faSearch} size="sm" color={'#546E7A'} />
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>
            {administradores.length === 0 && !state.appReducer.loading && <div className="w3-block bs-bbox">
                <p>No hay resultados</p>
            </div>}
            {administradores.length > 0 ? <div className="w3-block w3-margin-top">
                <table className="w3-table w3-border w3-bordered tabla w3-white">
                    <thead className="w3-border">
                        <tr>
                            <th>Usuario</th>
                            <th>Nombre y Apellido</th>
                            <th>Email</th>
                            <th>Tipo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="w3-border">
                        {administradores.filter(d => Helper.match(d, searchText, ['usuario', 'nombre', 'apellido', 'email'])).map((admr, index) => <tr key={admr.id} className={admr.anchorEl ? 'w3-light-gray' : ''}>
                            <td>{admr.usuario}</td>
                            <td>{(admr.nombre) && <span>{admr.nombre}&nbsp;</span>}{(admr.apellido) && <span>{admr.apellido}</span>}</td>
                            <td>{admr.email ? admr.email : '--'}</td>
                            <td>{admr.tipo}</td>
                            <td style={{ width: '120px' }}>
                                <BotonAccionInfo variant="contained" color="primary" aria-controls={'menu-opciones-' + admr.id} aria-haspopup="true" onClick={(e) => onClickOpciones(e, index)}>
                                    Opciones
                                </BotonAccionInfo>
                                <Menu
                                    id={'menu-opciones-' + admr.id}
                                    anchorEl={admr.anchorEl}
                                    keepMounted
                                    open={Boolean(admr.anchorEl)}
                                    onClose={() => clickOpcion('cerrar', admr, index)}>
                                    <MenuItem onClick={() => clickOpcion('ver', admr, index)}>Ver</MenuItem>
                                    <MenuItem onClick={() => clickOpcion('editar', admr, index)}>Editar</MenuItem>
                                    <MenuItem onClick={() => clickOpcion('eliminar', admr, index)}>Eliminar</MenuItem>                                 
                                </Menu>
                            </td>
                            {/* <td style={{ width: '260px' }}>
                                <BotonAccionInfo onClick={() => ver(admr)} variant="contained" size="small">Ver</BotonAccionInfo>
                                <BotonAccion onClick={() => editar(admr)} color="primary" variant="contained" size="small">Editar</BotonAccion>
                                <BotonAccion onClick={() => eliminar(admr)} color="secondary" variant="contained" size="small">Eliminar</BotonAccion>
                            </td> */}
                        </tr>)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="5" className="w3-small">
                                <div style={{ width: '100%', maxWidth: '100px', float: 'right' }}>
                                    <div className="w3-cell w3-cell-middle"><span>{paginador.numResultados} {(paginador.numResultados === 1) ? 'resultado' : 'resultados'}</span></div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div> : null}


            {/* {!state.appReducer.loading && <ReactPaginate pageCount={2}
                pageRangeDisplayed={5}
                marginPagesDisplayed={5}
                pageClassName="w3-btn w3-border paginator-li"
                previousLabel="Anterior"
                nextLabel="Siguiente"
                activeClassName="w3-blue"
                previousClassName="w3-btn w3-border paginator-li"
                nextClassName="w3-btn w3-border paginator-li" />} */}

            <SlidingPanel type={'right'} isOpen={panelVer} size={slideSize(windowSize)}>
                <PanelContainer close={cancelar} panelLabel="Administrador" closeLabel="Administradores">
                    <VerAdministrador cancelar={cancelar} administrador={administrador} optionsSelectTipo={optionsSelectTipo} />
                </PanelContainer>
            </SlidingPanel>

            <SlidingPanel type={'right'} isOpen={panelCrear} size={slideSize(windowSize)}>
                <PanelContainer close={cancelar} panelLabel="Nuevo Administrador" closeLabel="Administradores">
                    <CrearAdministrador cancelar={cancelar} getAdministradores={getAdministradores} optionsSelectTipo={optionsSelectTipo} />
                </PanelContainer>
            </SlidingPanel>

            <SlidingPanel type={'right'} isOpen={panelEditar} size={slideSize(windowSize)}>
                <PanelContainer close={cancelar} panelLabel="Edición Administrador" closeLabel="Administradores">
                    <EditarAdministrador cancelar={cancelar} administrador={administrador} getAdministradores={getAdministradores} optionsSelectTipo={optionsSelectTipo} />
                </PanelContainer>
            </SlidingPanel>

            {(modalEliminar && administrador) ? <div className="w3-modal w3-show">
                <div className="w3-modal-content w3-animate-zoom w3-round-large">
                    <div className="w3-row">
                        <div className="w3-col w3-right" style={{ width: '50px' }}>
                            <IconButton aria-label="delete" onClick={() => cancelar()}>
                                &nbsp;<FontAwesomeIcon icon={faTimes} />&nbsp;
                            </IconButton>
                        </div>
                        <div className="w3-rest w3-padding w3-center w3-text-gray">
                            <h2 style={{margin: 0}}><FontAwesomeIcon icon={faExclamationTriangle} /> Atención</h2>
                        </div>
                    </div>
                    <div className="w3-container w3-padding">
                        <p className="w3-large w3-margin w3-center">Estás a punto de eliminar al administrador {(!administrador.nombre && !administrador.apellido) && <b>{administrador.usuario}</b>}{administrador.nombre && <b>{administrador.nombre};&nbsp;</b>}{administrador.apellido && <b>{administrador.apellido}</b>}. Ésta acción no se puede deshacer.</p>
                        <p className="w3-large w3-margin w3-center">¿Quieres continuar?</p>
                        <div className="w3-row w3-white">
                            <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                                <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                                    type="button"
                                    onClick={() => cancelar()}>No</button>
                            </div>
                            <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                                <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                                    type="button"
                                    onClick={() => confirmarEliminar()}>Si</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null}
        </div>
    );
};
export default withRouter(Administradores);