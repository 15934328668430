import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { DispatchContext } from 'context';
// import { StateContext, DispatchContext } from 'context';
import './secciones.css';
import { useSnackbar } from 'notistack';
import { Colors } from 'styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDropzone } from 'react-dropzone';

const NuevaImagen = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    // const state = useContext(StateContext);
    const [valor, setValor] = useState(props.valor);
    const [errores, setErrores] = useState({});
    const [fotoFile, setFotoFile] = useState(null);
    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setFotoFile(acceptedFiles[0]);
            setErrores({ ...errores, foto: false });
            console.log('acceptedFiles[0]', acceptedFiles[0]);
        }
    });
    const cerrar = () => {
        props.cancelar();
        props.getSeccion();
    }
    useEffect(() => {
    }, []);
    const guardarImagen = () => {
        if (fotoFile) {
            dispatch(AppActions.setLoading(true));
            dispatch(AppActions.setActionsDisabled(true));
            var ruta = Api.route(`a/modificaciones/${valor.parteId}/imagen`);
            var clave = 'foto';
            var params = {};
            Api.uploadImageAxios(ruta, clave, fotoFile, params, function (response) {
                Helper.log('uploadFoto response', response);
                if (response.data.error) {
                    enqueueSnackbar(response.data.msg, { variant: 'error' });
                } else {
                    cerrar();
                }
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
            }, function (error) {
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                enqueueSnackbar('Error al subir la foto', { variant: 'error' });
                Helper.log('error uploadFoto', error);
            });
        }        
    }
    return (
        <div id="nueva-imagen">
            {valor ? <div className="w3-padding-top bs-bbox w3-white">
                {valor.tipo === 'slider' ? <div className="w3-padding">
                    <div className="w3-block w3-border w3-padding w3-round-small">
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <FontAwesomeIcon icon={faCloudUploadAlt} size="lg" />
                            <p>Arrastrar o subir imagen</p>
                        </div>
                        <aside className="thumbs-container">
                            {files.map(file => (<div className="thumb-box" key={file.name}>
                                <div className="thumb-inner">
                                    <img src={file.preview} alt="thumb" style={{
                                        display: 'block',
                                        width: 'auto',
                                        height: '100%'
                                    }} />
                                </div>
                            </div>))}
                        </aside>
                        {errores.foto ? <span style={{ color: Colors.ALERT }}>&nbsp;Foto es requerida</span> : null}
                    </div>
                    <br />
                    <div className="w3-row w3-white">
                        <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                            <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                                type="button"
                                onClick={() => props.cancelar()}>Cerrar</button>
                        </div>
                        <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                            <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                                type="button"
                                disabled={!files || (files && files.length === 0)}
                                onClick={() => guardarImagen()}>Guardar imagen</button>
                        </div>
                    </div>
                </div> : null}                
            </div> : null}
        </div>
    );
};
export default withRouter(NuevaImagen);