import React, { useState, useContext, useEffect } from 'react';
import CONFIG from "config";
import { withRouter } from "react-router-dom";
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './perfil.css';
import { useWindowSize } from 'hooks';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: 'auto',
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    nombreUsuario: {
        margin: '0px!important'
    },
    cargo: {
        color: '#546E7A',
        fontSize: '14px',
        margin: '0px'
    }
}));

const Perfil = props => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const windowSize = useWindowSize();
    const [data, setData] = useState(null);
    const classes = useStyles();
    const [datosUsuario, setDatosUsuario] = useState({
        nombre: 'Nombre',
        tipo: 'Tipo usuario'
    });

    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Dashboard';
        getDashboard();
        if (state.authReducer.usuario) {
            let nombre = '';
            if (state.authReducer.usuario.nombre) {
                nombre = state.authReducer.usuario.nombre;
            }
            if (state.authReducer.usuario.apellido) {
                nombre = nombre + ' ' + state.authReducer.usuario.apellido;
            }
            let tipo = '';
            if (state.authReducer.usuario.tipo) {
                tipo = state.authReducer.usuario.tipo;
            }
            setDatosUsuario({
                ...datosUsuario,
                nombre: nombre,
                tipo: tipo
            });
        }
    }, []);
    const getDashboard = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('a/dashboard'),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getDashboard response', response);
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                setData(response.data.datos);
            }
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
        }, error => {
            Helper.log('error getDashboard', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer datos', { variant: 'error' });
        });
    }
    return (
        <div>
            <div className="w3-row">
                <div className="w3-col l4 m4 s12 w3-padding">
                    <div className="w3-border w3-round w3-white">
                        <div className="w3-padding w3-center">
                            <Avatar alt={datosUsuario.nombre} src="assets/img/avatar-placeholder.png" className={classes.avatar} />
                            <Typography variant="h4" noWrap>{datosUsuario.nombre}</Typography>
                            <Typography variant="inherit" noWrap className={classes.cargo}>{datosUsuario.tipo}</Typography>
                        </div>
                        {/* <div className="w3-padding w3-center">
                            <Avatar alt="Remy Sharp" src="assets/img/avatar-placeholder.png" className={classes.avatar} />
                            <Typography variant="h4" noWrap>Fulano</Typography>
                            <Typography variant="inherit" noWrap className={classes.cargo}>Administrador</Typography>
                        </div> */}
                    </div>
                </div>
                <div className="w3-col l8 m8 s12"></div>
            </div>
        </div>
    );
}
export default withRouter(Perfil);