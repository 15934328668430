import React, { useState, useContext, useEffect } from 'react';
import CONFIG from "config";
import { Helper } from 'utils';
import { Api } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronLeft, faChevronRight, faTimes, faExclamationTriangle, faStar } from '@fortawesome/free-solid-svg-icons';
import SlidingPanel from 'react-sliding-side-panel';
import VerProducto from './ver-producto';
import CrearProducto from './crear-producto';
import EditarProducto from './editar-producto';
import { PanelContainer } from 'components/sidenav';
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './productos.css';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';

import { useWindowSize } from 'hooks';
import { Colors } from 'styles';
import { useSnackbar } from 'notistack';

import ReactSelect from 'react-select';


const OutlinedInputSmall = withStyles({
    root: {
        height: 45,
        backgroundColor: Colors.WHITE
    }
})(OutlinedInput);

const BootstrapInput = withStyles((theme) => ({
    input: {
        fontSize: 16,
        padding: '10px 26px 10px 12px',
    },
}))(InputBase);

const BotonAccionInfo = withStyles({
    root: {
        background: `${Colors.INFO}`,
        margin: '4px',
        color: 'white',
        '&:hover': {
            background: `${Colors.INFO}`,
        }
    },
    label: {
        textTransform: 'uppercase',
    }
})(Button);


const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const Productos = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [productos, setProductos] = useState([]);
    const [producto, setProducto] = useState(null);
    const [panelCrear, setPanelCrear] = useState(false);
    const [panelVer, setPanelVer] = useState(false);
    const [panelEditar, setPanelEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const windowSize = useWindowSize();
    //SEARCH
    let searchInput = React.createRef();
    const [requestEnProceso, setRequestEnProceso] = useState(false);
    const [searchText, setSearchText] = useState('');
    // const [typing, setTyping] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [ultimoFiltradoHecho, setUltimoFiltradoHecho] = useState(false);
    const [paginador, setPaginador] = useState({
        cuantos: 10,
        selectCuantos: [{ label: '10', value: 10 }, { label: '20', value: 20 }, { label: '30', value: 30 }, { label: '50', value: 50 }, { label: '100', value: 100 }],
        numResultados: 0,
        paginaActual: 0,
        cantidadPaginas: 1,
        maxSize: 10
    });
    const [filtros, setFiltros] = useState({
        cuantos: paginador.cuantos,
        desde: 0,
        filtros: {
            nombre: [''],
			codigo: [''],
        }
    });
    const [configProductos, setConfigProductos] = useState(null);
    const [selectOptions, setSelectOptions] = useState([]);
    const [jerarquiaId, setJerarquiaId] = useState('');
    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Productos';
        if (state.authReducer.usuario && state.authReducer.usuario.id) {
            if (state && state.appReducer && state.appReducer.filtrosProductos) {
                setUltimoFiltradoHecho(true);
                getConfig(state.appReducer.filtrosProductos);
            } else {
                getConfig(filtros);
            }
        } else {
            const { history } = props;
            if (history) history.push('/login');
        }
    }, []);
    useEffect(() => {
        searchInput.current.focus();
    }, [productos]);
    useEffect(() => {
        if (configProductos) {
            let options = [];
            options.push({ value: '', label: 'Todas las categorías' });
            for (let i in configProductos.jerarquias) {
                options.push({ value: configProductos.jerarquias[i].id, label: configProductos.jerarquias[i].nombre });
            }
            setSelectOptions(options);
            if (ultimoFiltradoHecho) {
                if (state && state.appReducer && state.appReducer.filtrosProductos) {
                    let fts = { ...state.appReducer.filtrosProductos };
                    if (fts.filtros.jerarquiaId) {
                        for (let i in options) {
                            if (options[i].value === fts.filtros.jerarquiaId) {
                                setJerarquiaId(options[i]);
                            }
                        }
                    }
                    if (fts.filtros.nombre && fts.filtros.nombre.length > 0) {
                        setSearchText(fts.filtros.nombre[0]);
                    }
                }

            }
        }
    }, [configProductos]);
    // const cambiarPagina = (data) => {
    //     console.log('cambiarPagina', data);
    //     const paginaActual = data.selected;
    //     if (!requestEnProceso) {
    //         const fts = { ...filtros, desde: paginador.cuantos * (paginaActual) };
    //         setFiltros(fts);
    //         setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginaActual }));
    //         buscarResultados(fts);
    //     }
    // }
    const cambioCuantos = (event) => {
        const cts = event.target.value;
        const fts = { ...filtros, desde: 0, cuantos: cts };
        setPaginador({ ...paginador, paginaActual: 0, cuantos: cts });
        buscarResultados(fts);
    }
    const cambioTextoBusqueda = (event) => {
        setSearchText(event.target.value);
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        let texto = (!event.target.value) ? "" : event.target.value;
        let fts = { ...filtros, desde: 0 };
        fts.filtros.nombre[0] = texto;
		if (fts.filtros.codigo === undefined) {
			fts.filtros = {...fts.filtros, codigo: [texto]}
		} else {
			fts.filtros.codigo[0] = texto;
		}
        setPaginador((pgndr) => {
            return {
                ...pgndr,
                paginaActual: 0
            }
        });
        // setTyping(false);
        setTypingTimeout(setTimeout(() => buscarResultados(fts), 1500));
    }
    const getConfig = (fts) => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('a/config-items'),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getConfigProductos response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                setConfigProductos(response.data.datos);
                buscarResultados(fts);
            }
        }, error => {
            Helper.log('error getConfigProductos', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer datos de configuración', { variant: 'error' });
        });
    }
    const buscarResultados = (fts) => {
        setFiltros(fts);
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        setRequestEnProceso(true);
        var options = {
            url: Api.route('a/ver-items'),
            method: 'POST',
            data: fts
        };
        Api.requestAxios(options, response => {
            Helper.log('searchProductos response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                props.addToast(response.data.msg, 'danger');
            } else {
                setPaginador((pgndr) => {
                    return {
                        ...pgndr,
                        numResultados: response.data.datos.numResultados,
                        cantidadPaginas: Math.ceil(response.data.datos.numResultados / fts.cuantos)
                    }
                });
                setProductos(response.data.datos.items);
                if (state && state.appReducer) {
                    dispatch(AppActions.setFiltrosProductos(fts));
                }
            }
            setRequestEnProceso(false);
        }, error => {
            Helper.log('error searchProductos', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            setRequestEnProceso(false);
        });
    }
    const atras = () => {
        if (filtros.desde > 0) {
            if (!requestEnProceso) {
                const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual - 1) };
                setFiltros(fts);
                setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual - 1 }));
                buscarResultados(fts);
            }
        }
    }
    const siguiente = () => {
        if (filtros.desde + paginador.cuantos < paginador.numResultados) {
            if (!requestEnProceso) {
                const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual + 1) };
                setFiltros(fts);
                setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual + 1 }));
                buscarResultados(fts);
            }
        }
    }
    const slideSize = (wz) => {
        if (wz.width < 1180) {
            return 100;
        } else {
            return Math.floor((1180 * 100) / wz.width);
        }
    }
    //VER
    const ver = (data) => {
        setProducto({ ...data, abm: 'ver' });
        setPanelVer(true);
    }
    //CREAR
    const crear = () => {
        setPanelCrear(true);
    }
    //EDITAR
    const editar = (data) => {
        setProducto({ ...data, abm: 'editar' });
        setPanelEditar(true);
    }
    //ELIMINAR
    const eliminar = (data) => {
        setProducto({ ...data, abm: 'eliminar' });
        setModalEliminar(true);
    }
    const confirmarEliminar = () => {
        var options = {
            url: Api.route('a/items/' + producto.id),
            method: 'DELETE'
        };
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        Api.requestAxios(options, response => {
            Helper.log('deleteProducto response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                enqueueSnackbar(response.data.msg, { variant: 'success' });
                buscarResultados(filtros);
                cancelar();
            }
        }, error => {
            Helper.log('error deleteProducto', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al eliminar administrador', { variant: 'error' });
        });
    }
    //CANCELAR
    const cancelar = () => {
        setPanelVer(false);
        setPanelCrear(false);
        setPanelEditar(false);
        setModalEliminar(false);
        setTimeout(() => {
            setProducto(null);
        }, 500);
    }
    const clickOpcion = (opcion, data, index) => {
        let pdts = [...productos];
        pdts[index].anchorEl = null;
        setProductos(pdts);
        switch (opcion) {
            case 'ver':
                ver(data);
                break;
            case 'editar':
                editar(data);
                break;
            case 'eliminar':
                eliminar(data);
                break;
            default:
                break;
        }
    }
    const onClickOpciones = (event, index) => {
        let pdts = [...productos];
        pdts[index].anchorEl = event.currentTarget;
        setProductos(pdts);
    }
    const handleChangeJerarquiaId = (selected) => {
        setJerarquiaId(selected);
        console.log('selected', selected);
        let fts = { ...filtros, desde: 0 };
        fts.filtros.jerarquiaId = selected.value;
        if (fts.filtros.jerarquiaId === '') {
            delete fts.filtros.jerarquiaId;
        }
        setPaginador((pgndr) => {
            return {
                ...pgndr,
                paginaActual: 0
            }
        });
        buscarResultados(fts);
    };
    return (
        <div>
            <div className="w3-row">
                <div className="w3-col w3-right w3-right-align" style={{ width: '300px' }}>
                    <Button onClick={() => crear()} color="primary" variant="contained">Agregar producto</Button>
                </div>
                <div className="w3-rest">
                    <h3>Productos</h3>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col" style={{ width: '250px' }}>
                    <FormControl variant="outlined" style={{ width: '100%', maxWidth: '420px' }}>
                        <OutlinedInputSmall
                            ref={searchInput}
                            placeholder="Buscar producto"
                            id="input-search"
                            type="text"
                            fullWidth={true}
                            value={searchText}
                            onChange={(e) => cambioTextoBusqueda(e)}
                            disabled={(state.appReducer.actionsDisabled) ? true : false}
                            startAdornment={
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faSearch} size="sm" color={'#546E7A'} />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
                <div className="w3-col" style={{ width: '250px', paddingLeft: '10px' }}>
                    {selectOptions.length > 0 ? <div style={{ width: '200px' }}>
                        <ReactSelect
                            name="jerarquiaId"
                            value={jerarquiaId}
                            onChange={handleChangeJerarquiaId}
                            options={selectOptions}
                            placeholder="Categorías"
                            isDisabled={state.appReducer.actionsDisabled} />
                    </div> : null}
                </div>
            </div>
            {productos.length === 0 && !state.appReducer.loading && <div className="w3-block bs-bbox">
                <h3 className="w3-text-gray">No hay resultados</h3>
            </div>}
            {productos.length > 0 ? <div className="w3-block w3-margin-top">
                <table className="w3-table w3-border w3-bordered tabla w3-white">
                    <thead className="w3-border">
                        <tr>
                            {/*<th>Id</th>*/}
                            <th>Orden</th>
                            <th>Nombre</th>
							<th>Código</th>
                            <th>Categoría</th>
                            <th>Precio</th>
                            <th>Foto</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="w3-border">
                        {productos.map((item, index) => <tr key={item.id} className={item.anchorEl ? 'selected w3-light-gray' : ''}>
                            {/*<td>{item.id}</td>*/}
                            <td>{item.orden}</td>
                            <td>{item.destacado === '1' ? <FontAwesomeIcon icon={faStar} size="1x" color={'#FAD02C'} style={{marginRight: '5px'}}/> : null}{item.nombre}</td>
							<td>{item.codigo !== '' && item.codigo !== null ? item.codigo : '-'}</td>
                            <td>{item.jerarquias.map((categ) => <div key={categ.id} className="xtag-gray">{categ.nombre}</div>)}</td>
                            <td>{item.precio ? Helper.numberUi(item.precio) + ' Gs.' : '--'}</td>
                            <td>
                                <div className="thumb-container w3-gray"
                                    style={{
                                        backgroundImage: "url('" + (item.multimedia && item.multimedia[0] && item.multimedia[0].url ? Api.route(item.multimedia[0].url) : '') + "')"
                                    }}>
                                </div>
                            </td>
                            <td style={{ width: '120px' }}>
                                <BotonAccionInfo variant="contained" color="primary" aria-controls={'menu-opciones-' + item.id} aria-haspopup="true" onClick={(e) => onClickOpciones(e, index)}>
                                    Opciones
                                </BotonAccionInfo>
                                <Menu
                                    id={'menu-opciones-' + item.id}
                                    anchorEl={item.anchorEl}
                                    keepMounted
                                    open={Boolean(item.anchorEl)}
                                    onClose={() => clickOpcion('cerrar', item, index)}>
                                    <MenuItem onClick={() => clickOpcion('ver', item, index)}>Ver</MenuItem>
                                    <MenuItem onClick={() => clickOpcion('editar', item, index)}>Editar</MenuItem>
                                    <MenuItem onClick={() => clickOpcion('eliminar', item, index)}>Eliminar</MenuItem>
                                </Menu>
                            </td>
                            {/* <td style={{ width: '260px' }}>
                                <BotonAccionInfo onClick={() => ver(item)} variant="contained" size="small">Ver</BotonAccionInfo>
                                <BotonAccion onClick={() => editar(item)} color="primary" variant="contained" size="small">Editar</BotonAccion>
                                <BotonAccion onClick={() => eliminar(item)} color="secondary" variant="contained" size="small">Eliminar</BotonAccion>
                            </td> */}
                        </tr>)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="7" className="w3-small">
                                <div style={{ width: '100%', maxWidth: '300px', float: 'right' }}>
                                    <div className="w3-cell w3-cell-middle"><span>Filas por página </span></div>
                                    <div className="w3-cell">
                                        {paginador.selectCuantos.length > 0 ? <FormControl variant="outlined">
                                            <Select
                                                size="small"
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={paginador.cuantos}
                                                onChange={(e) => cambioCuantos(e)}
                                                input={<BootstrapInput />}>
                                                {paginador.selectCuantos.map((option) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
                                            </Select>
                                        </FormControl> : null}
                                    </div>
                                    <div className="w3-cell w3-cell-middle"><span>{filtros.desde + 1} - {((filtros.desde + paginador.cuantos) > paginador.numResultados) ? paginador.numResultados : filtros.desde + paginador.cuantos} de {paginador.numResultados} </span></div>
                                    <div className="w3-cell w3-cell-middle">
                                        <IconButton aria-label="Atrás" onClick={() => atras()} size="small" className={classes.margin} disabled={filtros.desde <= 1}>
                                            <FontAwesomeIcon icon={faChevronLeft} size="sm" />
                                        </IconButton>
                                        <IconButton aria-label="Siguiente" onClick={() => siguiente()} size="small" className={classes.margin} disabled={((filtros.desde + paginador.cuantos) > paginador.numResultados)}>
                                            <FontAwesomeIcon icon={faChevronRight} size="sm" />
                                        </IconButton>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div> : null}


            {/* {!state.appReducer.loading && <ReactPaginate pageCount={2}
                pageRangeDisplayed={5}
                marginPagesDisplayed={5}
                pageClassName="w3-btn w3-border paginator-li"
                previousLabel="Anterior"
                nextLabel="Siguiente"
                activeClassName="w3-blue"
                previousClassName="w3-btn w3-border paginator-li"
                nextClassName="w3-btn w3-border paginator-li" />} */}

            <SlidingPanel type={'right'} isOpen={panelVer} size={slideSize(windowSize)}>
                <PanelContainer close={cancelar} panelLabel="Ver producto" closeLabel="Productos">
                    <VerProducto cancelar={cancelar} producto={producto} configProductos={configProductos} />
                </PanelContainer>
            </SlidingPanel>

            <SlidingPanel type={'right'} isOpen={panelCrear} size={slideSize(windowSize)}>
                <PanelContainer close={cancelar} panelLabel="Nuevo producto" closeLabel="Productos">
                    <CrearProducto cancelar={cancelar} configProductos={configProductos} buscarResultados={buscarResultados} filtros={filtros} />
                </PanelContainer>
            </SlidingPanel>

            <SlidingPanel type={'right'} isOpen={panelEditar} size={slideSize(windowSize)}>
                <PanelContainer close={cancelar} panelLabel="Edición producto" closeLabel="Productos">
                    <EditarProducto cancelar={cancelar} producto={producto} configProductos={configProductos} buscarResultados={buscarResultados} filtros={filtros} productos={productos} />
                </PanelContainer>
            </SlidingPanel>

            {(modalEliminar && producto) ? <div className="w3-modal w3-show">
                <div className="w3-modal-content w3-animate-zoom w3-round-large">
                    <div className="w3-row">
                        <div className="w3-col w3-right" style={{ width: '50px' }}>
                            <IconButton aria-label="delete" onClick={() => cancelar()}>
                                &nbsp;<FontAwesomeIcon icon={faTimes} />&nbsp;
                            </IconButton>
                        </div>
                        <div className="w3-rest w3-padding w3-center w3-text-gray">
                            <h2 style={{ margin: 0 }}><FontAwesomeIcon icon={faExclamationTriangle} /> Atención</h2>
                        </div>
                    </div>
                    <div className="w3-container w3-padding">
                        <p className="w3-large w3-margin w3-center">Estás a punto de eliminar el producto {producto.nombre}. Ésta acción no se puede deshacer.</p>
                        <p className="w3-large w3-margin w3-center">¿Quieres continuar?</p>
                        <div className="w3-row w3-white">
                            <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                                <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                                    type="button"
                                    onClick={() => cancelar()}>No</button>
                            </div>
                            <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                                <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                                    type="button"
                                    onClick={() => confirmarEliminar()}>Si</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null}
        </div>
    );
};
export default withRouter(Productos);