import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import CONFIG from "config";
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './secciones.css';
import { useSnackbar } from 'notistack';
import { Colors } from 'styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

import TextField from '@material-ui/core/TextField';


import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
// import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';


// import Paper from '@material-ui/core/Paper';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';

import { useDropzone } from 'react-dropzone';

const EditarValor = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [valor, setValor] = useState(null);
    const [original, setOriginal] = useState(null);
    const [errores, setErrores] = useState({});
    // const [hayCambios, setHayCambios] = useState(false);
    const [tabIdioma, setTabIdioma] = useState(null);
    const [enviado, setEnviado] = useState(null);
    // const [fotoUrl, setFotoUrl] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    // const [subiendoImagen, setSubiendoImagen] = useState(null);

    const [fotoFile, setFotoFile] = useState(null);
    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setFotoFile(acceptedFiles[0]);
            setErrores({ ...errores, foto: false });
            console.log('acceptedFiles[0]', acceptedFiles[0]);
        }
    });

    // const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const cerrar = () => {
        props.cancelar();
        props.getSeccion();
    }
    useEffect(() => {
        console.log('props.valor', props.valor);
        let vlr = props.valor;
        for (var i in vlr) {
            if (vlr[i].tipo === 'html') {
                vlr[i].valorEdit = EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(vlr[i].valor)
                    )
                );
                vlr[i].valorHtml = stateToHTML(vlr[i].valorEdit.getCurrentContent());
                vlr[i].valorEditHtml = stateToHTML(vlr[i].valorEdit.getCurrentContent());
            }
            if (vlr.length === 1 && vlr[0].valor && vlr[0].tipo === 'imagen') {
                setFile(CONFIG.URL_BASE + vlr[0].valor);
            }
        }
        setValor([...vlr]);
        setOriginal([...vlr]);
        setTabIdioma(CONFIG.DEFAULT_LANG);
    }, []);
    const setFile = async (url) => {
        const name = url.split('\\').pop().split('/').pop();
        const type = 'image/' + name.split('.').pop();
        let file = await fetch(url)
            .then(r => r.blob())
            .then(blobFile => new File([blobFile], name, { type: type }));
        setFotoFile(file);
        setFiles([Object.assign(file, {
            preview: url
        })]);
        setErrores({ ...errores, foto: false });
        console.log('file', file);
    };
    const handleChange = (event, index, campo) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let vlr = [...valor];
        vlr[index].valorEdit = value;
        setValor(vlr);
        setErrores({ ...errores, [name]: false });
    };
    const handleChangeHtml = (newValue, index, campo) => {
        let vlr = [...valor];
        vlr[index].valorEdit = newValue;
        vlr[index].valorEditHtml = stateToHTML(newValue.getCurrentContent());
        setValor(vlr);
        setErrores({ ...errores, [campo.nombre]: false });
        // console.log('stateToHTML(newValue.getCurrentContent())', stateToHTML(newValue.getCurrentContent()));     
    };
    // const guardarEdicionFoto = () => {
    // };
    const cancelarEdicion = () => {
    };
    const guardarEdicionVideo = () => {
    };
    const guardarImagen = (campo) => {
        if (fotoFile && campo.id) {
            dispatch(AppActions.setLoading(true));
            dispatch(AppActions.setActionsDisabled(true));
            var ruta = Api.route(`a/modificaciones/${campo.parteId}/imagen/${campo.id}`);
            var clave = 'foto';
            var params = {};
            Api.uploadImageAxios(ruta, clave, fotoFile, params, function (response) {
                Helper.log('uploadFoto response', response);
                if (response.data.error) {
                    enqueueSnackbar(response.data.msg, { variant: 'error' });
                } else {
                    cerrar();
                }
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
            }, function (error) {
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                enqueueSnackbar('Error al subir la foto', { variant: 'error' });
                Helper.log('error uploadFoto', error);
            });
        }
    }
    // const handleChangeTab = (event, newValue) => {
    //     setTabIdioma(newValue);
    // };
    const guardarEdicionTexto = (campo) => {
        const { parteId, id, tipo, valorEdit } = campo;
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        let tp = (tipo === 'html') ? 'texto' : tipo;
        var options = {
            url: Api.route(`a/modificaciones/${parteId}/${tp}/${id}`),
            data: {
                id: id,
                valor: valorEdit
            },
            method: 'PUT'
        };
        if (tipo === 'html') {
            options.data.valor = campo.valorEditHtml;
        }
        Api.requestAxios(options, response => {
            Helper.log('guardarEdicion response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                enqueueSnackbar(response.data.msg, { variant: 'success' });
                cerrar();
            }
        }, error => {
            Helper.log('error guardarEdicion', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al guardar edición', { variant: 'error' });
        });
    };
    const deshacerCambios = (index, campo) => {
        let vlr = [...valor];
        vlr[index].valorEdit = EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(vlr[index].valor)
            )
        );
        vlr[index].valorEditHtml = stateToHTML(vlr[index].valorEdit.getCurrentContent());
        setValor(vlr);
        // setEditorState(EditorState.createWithContent(
        //     ContentState.createFromBlockArray(
        //         convertFromHTML(vlr[index].valor)
        //     )
        // ));
    };
    return (
        <div id="editar-valor">
            {valor ? <div className="w3-padding-top bs-bbox">
                {/* <div className="w3-cell" style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                    <Paper square>
                        <Tabs
                            value={tabIdioma}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChangeTab}
                            aria-label="Idiomas">
                            {CONFIG.LANGS.map(lang => {
                                if (lang.enabled) {
                                    return <Tab key={lang.id} label={lang.label} value={lang.id} />;
                                } else {
                                    return null;
                                }
                            })}
                        </Tabs>
                    </Paper>
                </div> */}

                {valor.length > 0 ? <div className="w3-white">
                    {valor.map((campo, indexCampo) => <div key={campo.id}>
                        {campo.tipo === 'imagen' ? <div className="w3-padding">
                            <div className="w3-block w3-border w3-padding w3-round-small">
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <FontAwesomeIcon icon={faCloudUploadAlt} size="lg" />
                                    <p>Arrastrar o presionar para cambiar imagen</p>
                                </div>
                                <aside className="thumbs-container">
                                    {files.map(file => (<div className="thumb-box" key={file.name}>
                                        <div className="thumb-inner">
                                            <img src={file.preview} alt="thumb" style={{
                                                display: 'block',
                                                width: 'auto',
                                                height: '100%'
                                            }} />
                                        </div>
                                    </div>))}
                                </aside>
                                {errores.foto ? <span style={{ color: Colors.ALERT }}>&nbsp;Foto es requerida</span> : null}
                            </div>
                            <br />
                            <div className="w3-row w3-white">
                                <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                                    <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                                        type="button"
                                        onClick={() => props.cancelar()}>Cerrar</button>
                                </div>
                                <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                                    <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                                        type="button"
                                        disabled={!files || (files && files.length === 0) || (valor && valor.length === 1 && files && files.length === 1 && files[0].preview === CONFIG.URL_BASE + valor[0].valor)}
                                        onClick={() => guardarImagen(campo)}>Guardar imagen</button>
                                </div>
                            </div>
                        </div> : null}
                        {campo.tipo === 'video' ? <div className="w3-padding">
                            {campo.tipo === 'video' ? <div className="w3-row">
                                <div className="w3-col l12">
                                    {!enviado ? <label id="label-video" className="file-button w3-blue w3-btn w3-medium">Seleccionar video
                                <input type="file" id="video" disabled={state.appReducer.actionsDisabled} onchange="angular.element(this).scope().cambioVideo(this);" accept="video/*" />
                                    </label> : null}
                                </div>
                                <div className="w3-col l12">
                                    {videoUrl ? <div style="width: 100%;max-width: 480px;">
                                        <video controls src={videoUrl} accept="'video/*'" autoplay style="width: 100%;max-width: 480px;" className="w3-card"></video>
                                    </div> : null}
                                </div>
                            </div> : null}
                            <div className="w3-row w3-padding">
                                <div className="w3-col l12">
                                    <button className="w3-btn w3-ripple w3-blue w3-border w3-border-blue w3-right"
                                        type="button"
                                        onClick={() => guardarEdicionVideo(campo)}
                                        disabled={campo.videoUrlInicial === videoUrl || state.appReducer.actionsDisabled}
                                        style="margin-left: 10px;"><i className="fas fa-spinner w3-spin" ng-show="ctrl.subiendoVideo"></i> Ok</button>
                                    <button className="w3-btn w3-ripple w3-light-gray w3-border w3-right"
                                        type="button"
                                        onClick={() => cancelarEdicion()}
                                        disabled={state.appReducer.actionsDisabled}>Cancelar</button>
                                </div>
                            </div>
                        </div> : null}
                        {(campo.idioma === tabIdioma || campo.tipo) ? <div>
                            {campo.tipo === 'texto' ? <div className="w3-row">
                                <div className="w3-col l12 w3-padding">
                                    <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                        multiline
                                        rowsMax={6}
                                        error={errores[campo.key]}
                                        name={campo.key}
                                        type="text"
                                        label={campo.nombre}
                                        value={campo.valorEdit}
                                        onChange={(e) => handleChange(e, indexCampo, campo)}
                                        disabled={state.appReducer.actionsDisabled} /><br /><br />
                                </div>
                            </div> : null}
                            {campo.tipo === 'texto-corto' ? <div className="w3-row">
                                <div className="w3-col l12">
                                    <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                        multiline
                                        rowsMax={2}
                                        error={errores[campo.key]}
                                        name={campo.key}
                                        type="text"
                                        label={campo.nombre}
                                        value={campo.valorEdit}
                                        onChange={(e) => handleChange(e, indexCampo, campo)}
                                        disabled={state.appReducer.actionsDisabled} /><br /><br />
                                </div>
                            </div> : null}
                            {campo.tipo === 'html' ? <div className="w3-row">
                                <div className="w3-col l12 w3-padding-small">
                                    <Editor
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        editorState={campo.valorEdit}
                                        onEditorStateChange={(newValue) => handleChangeHtml(newValue, indexCampo, campo)}
                                    />
                                </div>





                                {campo.valorEditHtml !== campo.valorHtml ? <div className="w3-col l12">
                                    <span className="w3-margin-left w3-small w3-text-grey">Hay cambios en el texto, para guardarlos, presione <b>Guardar cambios</b></span>
                                </div> : null}
                                {(campo.tipo !== 'imagen' && campo.tipo !== 'video') ? <div className="w3-row w3-white">
                                    <div className="w3-col l4" style={{ padding: '30px 16px' }}>
                                        <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                                            type="button"
                                            onClick={() => props.cancelar()}>Cerrar</button>
                                    </div>
                                    {campo.valorEditHtml !== campo.valorHtml ? <div className="w3-col l4" style={{ padding: '30px 16px' }}>
                                        <button className="w3-btn w3-ripple w3-light-gray w3-border w3-block w3-large w3-padding-large w3-text-gray w3-round"
                                            type="button"
                                            onClick={() => deshacerCambios(indexCampo, campo)}>Deshacer cambios</button>
                                    </div> : null}
                                    <div className="w3-col l4" style={{ padding: '30px 16px' }}>
                                        <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                                            type="button"
                                            disabled={campo.valorEditHtml === campo.valorHtml}
                                            onClick={() => guardarEdicionTexto(campo)}>Guardar cambios</button>
                                    </div>
                                </div> : null}









                            </div> : null}
                            {campo.tipo === 'iframe-code' ? <div className="w3-row">
                                <div className="w3-col l12 w3-padding-small">
                                    <textarea className="w3-input w3-border" rows="3"
                                        name={campo.key}
                                        value={campo.valorEdit}
                                        onChange={(e) => handleChange(e, indexCampo, campo)}
                                        disabled={state.appReducer.actionsDisabled}></textarea>
                                </div>
                            </div> : null}
                            {(campo.valorEdit !== campo.valor && campo.tipo !== 'html') ? <div className="w3-row">
                                <div className="w3-col l12">
                                    <span className="w3-margin-left w3-small w3-text-grey">Hay cambios en el texto, para guardarlos, presione <b>Guardar cambios</b></span>
                                </div>
                            </div> : null}
                            {(campo.tipo !== 'imagen' && campo.tipo !== 'video' && campo.tipo !== 'html') ? <div className="w3-row w3-padding">

                                <div className="w3-row w3-white">
                                    <div className="w3-col l4" style={{ padding: '30px 16px' }}>
                                        <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                                            type="button"
                                            onClick={() => props.cancelar()}>Cerrar</button>
                                    </div>
                                    {campo.valorEdit !== campo.valor ? <div className="w3-col l4" style={{ padding: '30px 16px' }}>
                                        <button className="w3-btn w3-ripple w3-light-gray w3-border w3-block w3-large w3-padding-large w3-text-gray w3-round"
                                            type="button"
                                            onClick={() => deshacerCambios(indexCampo, campo)}>Deshacer cambios</button>
                                    </div> : null}
                                    <div className="w3-col l4" style={{ padding: '30px 16px' }}>
                                        <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                                            type="button"
                                            disabled={campo.valorEdit === campo.valor}
                                            onClick={() => guardarEdicionTexto(campo)}>Guardar cambios</button>
                                    </div>
                                </div>
                            </div> : null}
                        </div> : null}
                    </div>)}
                </div> : null}
            </div> : null}
        </div>
    );
};
export default withRouter(EditarValor);