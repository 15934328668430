import React, { useState, useContext, useEffect } from 'react';
import CONFIG from "config";
import { withRouter, useParams } from "react-router-dom";
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './secciones.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faPencilAlt, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';


import SlidingPanel from 'react-sliding-side-panel';
import EditarValor from './editar-valor';
import NuevaImagen from './nueva-imagen';
import EditarImagen from './editar-imagen';
import { PanelContainer } from 'components/sidenav';
import Button from '@material-ui/core/Button';


import IconButton from '@material-ui/core/IconButton';

import { useWindowSize } from 'hooks';
import { useSnackbar } from 'notistack';

const Secciones = props => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const windowSize = useWindowSize();
    const [seccion, setSeccion] = useState(null);
    // const [secciones, setSecciones] = useState(null);
    const { seccionId } = useParams();
    const [panelEditar, setPanelEditar] = useState(false);
    const [panelNuevaImagen, setPanelNuevaImagen] = useState(false);
    const [panelEditarImagen, setPanelEditarImagen] = useState(false);
    const [modalEliminarImagen, setModalEliminarImagen] = useState(false);
    const [valorData, setValorData] = useState(null);
    const [imagen, setImagen] = useState(null);
    const [nuevaImagen, setNuevaImagen] = useState(null);

    const ruta = 'a/dashboard'; //get
    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Secciones';
    }, []);
    useEffect(() => {
        if (seccionId) {
            getSeccion();
        }
    }, [seccionId]);
    var inicializarSeccion = function (datos) {
        datos.partes.forEach((parte, indexParte) => {
            Object.keys(parte.grupos).forEach(keyGrupo => {
                let grupo = parte.grupos[keyGrupo];
                Object.keys(grupo).forEach(keyValor => {
                    let valor = grupo[keyValor];
                    var idiomas = {};
                    for (var i in valor) {
                        if (valor[i].idioma) {
                            idiomas[valor[i].idioma] = true;
                        }
                        valor[i].nombre = keyValor;
                        valor[i].valorEdit = valor[i].valor;
                    }
                    datos.partes[indexParte].grupos[keyGrupo][keyValor] = valor;
                    if (!Helper.isEmptyObject(idiomas)) {
                        parte.idiomas = idiomas;
                        datos.partes[indexParte].idiomas = idiomas;
                    }
                });
            });
        });
        setSeccion(datos);
    };


    const getSeccion = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('a/modificaciones/' + seccionId),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getSeccion response', response);
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                // setSecciones(response.data.datos);
                inicializarSeccion(response.data.datos[0]);
            }
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
        }, error => {
            Helper.log('error getSeccion', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer datos', { variant: 'error' });
        });
    }
    const editar = (parte, valor) => {
        for (var i in valor) {
            valor[i].parteId = parte.id;
        }
        setValorData([...valor]);
        setPanelEditar(true);
    }
    const agregarNuevaImagen = (parte) => {
        setNuevaImagen({ parteId: parte.id, tipo: parte.tipo, parteKey: parte.key });
        setPanelNuevaImagen(true);
    }
    const editarImagen = (parte, valor) => {
        setImagen({ ...valor, parteId: parte.id, parteKey: parte.key });
        setPanelEditarImagen(true);
    }
    const eliminarImagen = (parte, valor) => {
        setImagen({ ...valor, parteId: parte.id });
        setModalEliminarImagen(true);
    }
    const confirmarEliminar = () => {
        var options = {
            url: Api.route(`a/modificaciones/${imagen.parteId}/imagen/${imagen.id}`),
            method: 'DELETE'
        };
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        Api.requestAxios(options, response => {
            Helper.log('eliminarImagen response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                enqueueSnackbar(response.data.msg, { variant: 'success' });
                getSeccion();
                cancelar();
            }
        }, error => {
            Helper.log('error eliminarImagen', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al eliminar imagen', { variant: 'error' });
        });
    }
    const cancelar = () => {
        setPanelEditar(false);
        setPanelNuevaImagen(false);
        setPanelEditarImagen(false);
        setModalEliminarImagen(false);
        setValorData(null);
    }
    const slideSize = (wz) => {
        if (wz.width < 1100) {
            return 100;
        } else {
            return Math.floor((1100 * 100) / wz.width);
        }
    }
    return (
        <div id="secciones">
            {seccion ? <h1>Sección: {seccion.nombre}</h1> : null}
            {seccion ? <table className="w3-table tabla w3-bordered w3-border w3-margin-top table-td-middle w3-white">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Tipo</th>
                        {/* <th>Español</th>
                        <th>Inglés</th> */}
                        <th>Elemento/s</th>
                    </tr>
                </thead>
                <tbody>
                    {seccion.partes.map((parte) => <tr key={parte.id}>
                        <td>{parte.nombre}</td>
                        <td>{parte.tipo}</td>
                        {/* <td>
                            {parte.idiomas ? <input className="w3-check" type="checkbox" ng-if="parte.idiomas"
                                value={parte.idiomas.es} disabled /> : null}
                            {!parte.idiomas ? <span ng-if="!parte.idiomas">Sin idioma</span> : null}
                        </td>
                        <td>
                            {parte.idiomas ? <input className="w3-check" type="checkbox" ng-if="parte.idiomas"
                                ng-model="parte.idiomas.en" disabled /> : null}
                            {!parte.idiomas ? <span ng-if="!parte.idiomas">Sin idioma</span> : null}
                        </td> */}
                        <td>
                            <table className="w3-table w3-border">
                                <tbody>
                                    {Object.keys(parte.grupos).map((keyGrupo, indexGrupo) => <tr key={`grupo-${indexGrupo}`}>
                                        {/* { keyGrupo !== '~' ? <td rowspan={valor.length} style="width: 140px;">{keyGrupo}</td> : null } */}
                                        {keyGrupo !== '~' ? <td>
                                            {Object.keys(parte.grupos[keyGrupo]).map((keyValor, indexValor) => <div key={`valor-${indexValor}`} className="w3-row w3-border">
                                                <div className="w3-col w3-right" style={{ width: '100px' }}>
                                                    {parte.tipo !== "slider" ? <IconButton aria-label="delete" color="primary"
                                                        onClick={() => editar(parte, parte.grupos[keyGrupo][keyValor])}
                                                        disabled={state.appReducer.actionsDisabled}>
                                                        <FontAwesomeIcon size="sm" icon={faPencilAlt} />
                                                    </IconButton> : null}
                                                    {(parte.tipo === "slider" && parte.grupos[keyGrupo][keyValor].length === 1 && parte.grupos[keyGrupo][keyValor][0].tipo === 'imagen') ? <>
                                                    <IconButton aria-label="delete" color="primary"
                                                        onClick={() => editarImagen(parte, parte.grupos[keyGrupo][keyValor][0])}
                                                        disabled={state.appReducer.actionsDisabled}>
                                                        <FontAwesomeIcon size="sm" icon={faPencilAlt} />
                                                    </IconButton>
                                                    <IconButton aria-label="delete" color="secondary"
                                                        onClick={() => eliminarImagen(parte, parte.grupos[keyGrupo][keyValor][0])}
                                                        disabled={state.appReducer.actionsDisabled}>
                                                        <FontAwesomeIcon size="sm" icon={faTrashAlt} />
                                                    </IconButton>
                                                    </> : null}
                                                </div>
                                                <div className="w3-rest w3-padding-small">
                                                    {(keyValor) ? keyValor : 'Vacío'}
                                                </div>
                                            </div>)}
                                        </td> : null }
                                        {keyGrupo === '~' ? <td>
                                            {Object.keys(parte.grupos[keyGrupo]).map((keyValor, indexValor) => <div key={`valor-${indexValor}`} className="w3-row w3-border">
                                                <div className="w3-col w3-right" style={{ width: '50px' }}>
                                                    {parte.tipo !== "slider" ? <IconButton aria-label="delete" color="primary"
                                                        onClick={() => editar(parte, parte.grupos[keyGrupo][keyValor])}
                                                        disabled={state.appReducer.actionsDisabled}>
                                                        <FontAwesomeIcon size="sm" icon={faPencilAlt} />
                                                    </IconButton> : null}
                                                    {/* {(parte.tipo === "slider" && keyValor === '' && parte.grupos[keyGrupo][keyValor].length === 1 && parte.grupos[keyGrupo][keyValor][0].id === null) ? <IconButton aria-label="delete" color="primary"
                                                        onClick={() => editarImagen(parte, parte.grupos[keyGrupo][keyValor][0])}
                                                        disabled={state.appReducer.actionsDisabled}>
                                                        <FontAwesomeIcon size="sm" icon={faPencilAlt} />
                                                    </IconButton> : null } */}
                                                </div>
                                                <div className="w3-rest w3-padding-small">
                                                    {(keyValor) ? keyValor : 'Vacío'}
                                                </div>
                                            </div>)}
                                        </td> : null }
                                    </tr>)}
                                    {parte.tipo === "slider" ? <tr>
                                        <td>
                                            <Button style={{ marginTop: '10px' }} variant="contained" color="primary"
                                                onClick={() => agregarNuevaImagen(parte)}
                                                disabled={state.appReducer.actionsDisabled}>Nueva imagen</Button>
                                        </td>
                                    </tr> : null}
                                </tbody>
                            </table>
                        </td>
                    </tr>)}
                </tbody>
            </table> : null}


            <SlidingPanel type={'right'} isOpen={panelEditar} size={slideSize(windowSize)}>
                {valorData ? <PanelContainer close={cancelar} panelLabel={`Edición de ${valorData[0].nombre}`} closeLabel="Sección">
                    <EditarValor cancelar={cancelar} valor={valorData} getSeccion={getSeccion} />
                </PanelContainer> : null}
            </SlidingPanel>

            <SlidingPanel type={'right'} isOpen={panelNuevaImagen} size={slideSize(windowSize)}>
                {nuevaImagen ? <PanelContainer close={cancelar} panelLabel="Nueva imagen" closeLabel="Sección">
                    <NuevaImagen cancelar={cancelar} valor={nuevaImagen} getSeccion={getSeccion} />
                </PanelContainer> : null}
            </SlidingPanel>

            <SlidingPanel type={'right'} isOpen={panelEditarImagen} size={slideSize(windowSize)}>
                {imagen ? <PanelContainer close={cancelar} panelLabel="Edición imagen" closeLabel="Sección">
                    <EditarImagen cancelar={cancelar} valor={imagen} getSeccion={getSeccion} />
                </PanelContainer> : null}
            </SlidingPanel>

            {(modalEliminarImagen && imagen) ? <div className="w3-modal w3-show">
                <div className="w3-modal-content w3-animate-zoom w3-round-large">
                    <div className="w3-row">
                        <div className="w3-col w3-right" style={{ width: '50px' }}>
                            <IconButton aria-label="delete" onClick={() => cancelar()}>
                                &nbsp;<FontAwesomeIcon icon={faTimes} />&nbsp;
                            </IconButton>
                        </div>
                        <div className="w3-rest w3-padding w3-center w3-text-gray">
                            <h2 style={{margin: 0}}><FontAwesomeIcon icon={faExclamationTriangle} /> Atención</h2>
                        </div>
                    </div>
                    <div className="w3-container w3-padding">
                        <p className="w3-large w3-margin w3-center">Estás a punto de eliminar la imagen {imagen.nombre}: </p>
                        <div className="w3-block w3-center">
                            <img style={{width: '100%', maxWidth: '500px'}} src={CONFIG.URL_BASE + imagen.valor} alt={imagen.nombre}/>
                        </div>
                        
                        <p className="w3-large w3-margin w3-center">Ésta acción no se puede deshacer.</p>
                        <p className="w3-large w3-margin w3-center">¿Quieres continuar?</p>
                        <div className="w3-row w3-white">
                            <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                                <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                                    type="button"
                                    onClick={() => cancelar()}>No</button>
                            </div>
                            <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                                <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                                    type="button"
                                    onClick={() => confirmarEliminar()}>Si</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null}

        </div>
    );
}
export default withRouter(Secciones);