import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from "react-router-dom";
import './sidenav.css';
const Sidecontent = (props) => {
    const { children, labels, location } = props;
    const toggleSideMenu = (toggle) => {
        props.setAppState('showSidenav', toggle);
    };
    return (
        <>
            <div className="w3-overlay w3-hide-large w3-animate-opacity" style={{ cursor: 'pointer' }} onClick={() => toggleSideMenu(false)}></div>
            <div className="w3-main" style={{ marginLeft: '280px' }}>
                <div style={{ width: '100%', paddingTop: '1px' }}>
                    <div className={"w3-container w3-top w3-large w3-theme"} style={{ padding: '5px', zIndex: '4' }}>
                        <div className="w3-row">
                            <div className="w3-col l12 m12 s12">
                                <div>
                                    <FontAwesomeIcon icon={faBars} className="w3-margin-right w3-btn w3-theme-l2 w3-hide-large w3-large" onClick={() => toggleSideMenu(true)} />
                                    <span className="w3-text-white">
                                        <i className="fas fa-chart-bar w3-margin-right w3-hide-small" aria-hidden="true"></i>
                                        {labels[location.pathname]}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};
export default withRouter(Sidecontent);