import React, { useState, useContext, useEffect } from 'react';
import CONFIG from "config";
import { Helper } from 'utils';
import { Api } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes, faExclamationTriangle, faCircle } from '@fortawesome/free-solid-svg-icons';
import SlidingPanel from 'react-sliding-side-panel';
import VerPedido from './ver-pedido';
import CrearPedido from './crear-pedido';
import EditarPedido from './editar-pedido';
import { PanelContainer } from 'components/sidenav';
import { Switch } from 'components/switch';
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './pedidos.css';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Menu from '@material-ui/core/Menu';


// import { useWindowSize } from 'hooks';
import { Colors } from 'styles';
import { useSnackbar } from 'notistack';

import moment from 'moment';

// const OutlinedInputSmall = withStyles({
//     root: {
//         height: 45,
//         backgroundColor: Colors.WHITE
//     }
// })(OutlinedInput);

const BootstrapInput = withStyles((theme) => ({
    input: {
        fontSize: 16,
        padding: '10px 26px 10px 12px',
    },
}))(InputBase);

const BotonAccionInfo = withStyles({
    root: {
        background: `${Colors.INFO}`,
        margin: '4px',
        color: 'white',
        '&:hover': {
            background: `${Colors.INFO}`,
        }
    },
    label: {
        textTransform: 'uppercase',
    }
})(Button);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const PedidosHistorial = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [pedidos, setPedidos] = useState([]);
    const [pedido, setPedido] = useState(null);
    const [panelCrear, setPanelCrear] = useState(false);
    const [panelVer, setPanelVer] = useState(false);
    const [panelEditar, setPanelEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    // const windowSize = useWindowSize();
    //SEARCH
    // let searchInput = React.createRef();
    const [requestEnProceso, setRequestEnProceso] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [typing, setTyping] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [fechaDesde, setFechaDesde] = useState(null);
    const [fechaHasta, setFechaHasta] = useState(null);
    const [paginador, setPaginador] = useState({
        cuantos: 10,
        selectCuantos: [{ label: '10', value: 10 }, { label: '20', value: 20 }, { label: '30', value: 30 }, { label: '50', value: 50 }, { label: '100', value: 100 }],
        numResultados: 0,
        paginaActual: 0,
        cantidadPaginas: 1,
        maxSize: 10
    });
    const [filtros, setFiltros] = useState({
        cuantos: paginador.cuantos,
        desde: 0,
        filtros: {
            finalizado: false
        }
    });
    const [configPedidos, setConfigPedidos] = useState(null);
    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Productos';
        if (state.authReducer.usuario && state.authReducer.usuario.id) {
            getConfig(filtros);
        } else {
            const { history } = props;
            if (history) history.push('/login');
        }
    }, []);
    useEffect(() => {
        // searchInput.current.focus();
    }, [pedidos]);
    // const cambiarPagina = (data) => {
    //     console.log('cambiarPagina', data);
    //     const paginaActual = data.selected;
    //     if (!requestEnProceso) {
    //         const fts = { ...filtros, desde: paginador.cuantos * (paginaActual) };
    //         setFiltros(fts);
    //         setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginaActual }));
    //         buscarResultados(fts);
    //     }
    // }
    const cambioCuantos = (event) => {
        const cts = event.target.value;
        const fts = { ...filtros, desde: 0, cuantos: cts };
        setPaginador({ ...paginador, paginaActual: 0, cuantos: cts });
        buscarResultados(fts);
    }
    // const cambioTextoBusqueda = (event) => {
    //     setSearchText(event.target.value);
    //     if (typingTimeout) {
    //         clearTimeout(typingTimeout);
    //     }
    //     let texto = (!event.target.value) ? "" : event.target.value;
    //     let fts = { ...filtros };
    //     // fts.filtros.nombre[0] = texto;
    //     setTyping(false);
    //     setTypingTimeout(setTimeout(() => buscarResultados(fts), 1500));
    // }
    const onChangeFechaDesde = (date) => {
        let fts = { ...filtros };
        if (date) {
            let desde = moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
            console.log('desde', desde);
            setFechaDesde(date);
            if (filtros.filtros.fecha) {
                fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { ...filtros.filtros.fecha, desde: desde } } };
            } else {
                fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { desde: desde } } };
            }
        } else {
            setFechaDesde(null);
            if (filtros.filtros.fecha) {
                if (filtros.filtros.fecha.hasta) {
                    fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { hasta: filtros.filtros.fecha.hasta } } };
                } else {
                    let filtrosFiltros = { ...filtros.filtros };
                    delete filtrosFiltros.fecha;
                    fts = { ...filtros, filtros: { ...filtrosFiltros } };
                }
            }
        }
        buscarResultados(fts);
    };
    const onChangeFechaHasta = (date) => {
        let fts = { ...filtros };
        if (date) {
            let hasta = moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
            console.log('hasta', hasta);
            setFechaHasta(date);
            if (filtros.filtros.fecha) {
                fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { ...filtros.filtros.fecha, hasta: hasta } } };
            } else {
                fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { hasta: hasta } } };
            }
        } else {
            setFechaHasta(null);
            if (filtros.filtros.fecha) {
                if (filtros.filtros.fecha.desde) {
                    fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { desde: filtros.filtros.fecha.desde } } };
                } else {
                    let filtrosFiltros = { ...filtros.filtros };
                    delete filtrosFiltros.fecha;
                    fts = { ...filtros, filtros: { ...filtrosFiltros } };
                }
            }
        }
        buscarResultados(fts);
    };
    const getConfig = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('a/config-pedidos'),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getConfigPedidos response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                setConfigPedidos(response.data.datos);
                buscarResultados(filtros);
            }
        }, error => {
            Helper.log('error getConfigPedidos', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer datos de configuración', { variant: 'error' });
        });
    }
    const buscarResultados = (fts) => {
        setFiltros(fts);
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        setRequestEnProceso(true);
        var options = {
            url: Api.route('a/ver-pedidos'),
            method: 'POST',
            data: fts
        };
        Api.requestAxios(options, response => {
            Helper.log('searchPedidos response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                setPaginador((pgndr) => {
                    return {
                        ...pgndr,
                        numResultados: response.data.datos.numResultados,
                        cantidadPaginas: Math.ceil(response.data.datos.numResultados / fts.cuantos)
                    }
                });
                let peds = [];
                for (var i in response.data.datos.pedidos) {
                    let ped = {
                        ...response.data.datos.pedidos[i]
                    };
                    ped.estados = {
                        procesando: ped.procesando ? true : false,
                        cancelado: ped.cancelado ? true : false,
                        enCamino: ped.enCamino ? true : false,
                        entregado: ped.entregado ? true : false,
                        finalizado: ped.pagado == '1' ? true : false,
                        listo: ped.listo ? true : false,
                        pagado: ped.pagado == '1' ? true : false,
                    }
                    peds.push(ped);
                }
                setPedidos(peds);
            }
            setRequestEnProceso(false);
        }, error => {
            Helper.log('error searchPedidos', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            setRequestEnProceso(false);
        });
    }
    const atras = () => {
        if (filtros.desde > 0) {
            if (!requestEnProceso) {
                const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual - 1) };
                setFiltros(fts);
                setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual - 1 }));
                buscarResultados(fts);
            }
        }
    }
    const siguiente = () => {
        if (filtros.desde + paginador.cuantos < paginador.numResultados) {
            if (!requestEnProceso) {
                const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual + 1) };
                setFiltros(fts);
                setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual + 1 }));
                buscarResultados(fts);
            }
        }
    }
    const slideSize = (wz) => {
        if (wz.width < 1180) {
            return 100;
        } else {
            return Math.floor((1180 * 100) / wz.width);
        }
    }
    //VER
    const ver = (data) => {
        setPedido({ ...data, abm: 'ver' });
        setPanelVer(true);
    }
    //CREAR
    const crear = () => {
        setPanelCrear(true);
    }
    //EDITAR
    const editar = (data) => {
        console.log('pedido', data);
        setPedido({ ...data, abm: 'editar' });
        setPanelEditar(true);
    }
    //ELIMINAR
    const eliminar = (data) => {
        setPedido({ ...data, abm: 'eliminar' });
        setModalEliminar(true);
    }
    const confirmarEliminar = () => {
        var options = {
            url: Api.route('a/pedidos/' + pedido.id),
            method: 'DELETE'
        };
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        Api.requestAxios(options, response => {
            Helper.log('deletePedido response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                enqueueSnackbar(response.data.msg, { variant: 'success' });
                buscarResultados(filtros);
                cancelar();
            }
        }, error => {
            Helper.log('error deletePedido', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al eliminar administrador', { variant: 'error' });
        });
    }
    //CANCELAR
    const cancelar = () => {
        setPedido(null);
        setPanelVer(false);
        setPanelCrear(false);
        setPanelEditar(false);
        setModalEliminar(false);
    }
    const onClickOpciones = (event, index) => {
        let peds = [...pedidos];
        peds[index].anchorEl = event.currentTarget;
        setPedidos(peds);
    }
    const cerrarOpciones = () => {
        setAnchorEl(null);
    }
    const getPedido = (accion, id) => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        setRequestEnProceso(true);
        var options = {
            url: Api.route('a/pedidos/' + id),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getPedido response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                if (response.data.datos) {
                    switch (accion) {
                        case 'ver':
                            ver(response.data.datos);
                            break;
                        case 'editar':
                            editar(response.data.datos);
                            break;
                        default:
                            break;
                    }
                }

            }
        }, error => {
            Helper.log('error getPedido', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
        });
    }
    const getEstado = (ped) => {
        let status = {
            estado: 'Pendiente',
            color: '#2067FA'
        };
        if (ped.recibido) {
            status = {
                estado: 'Pendiente',
                color: '#2067FA'
            };
        }
        if (ped.procesando) {
            status = {
                estado: 'Procesando',
                color: '#666D92'
            };
        }
        if (ped.listo) {
            status = {
                estado: 'Listo',
                color: '#00C58D'
            };
        }
        if (ped.cancelado) {
            status = {
                estado: 'Cancelado',
                color: '#FC5C63'
            };
        }
        return status;
    }
    const clickOpcion = (opcion, data, index) => {
        let peds = [...pedidos];
        peds[index].anchorEl = null;
        setPedidos(peds);
        // setAnchorEl(null);
        switch (opcion) {
            case 'ver':
                getPedido('ver', data.id);
                break;
            case 'editar':
                getPedido('editar', data.id);
                break;
            case 'eliminar':
                eliminar(data);
                break;
            case 'estado-procesando':
                cambiarEstado(data.id, { campo: 'procesando', valor: true });
                break;
            case 'estado-listo':
                cambiarEstado(data.id, { campo: 'listo', valor: true });
                break;
            case 'estado-enCamino':
                cambiarEstado(data.id, { campo: 'enCamino', valor: true });
                break;
            case 'estado-entregado':
                cambiarEstado(data.id, { campo: 'entregado', valor: true });
                break;
            case 'estado-pagado':
                cambiarEstado(data.id, { campo: 'pagado', valor: true });
                break;
            case 'estado-cancelado':
                cambiarEstado(data.id, { campo: 'cancelado', valor: true });
                break;
            case 'estado-finalizado':
                cambiarEstado(data.id, { campo: 'finalizado', valor: true });
                break;
            default:
                break;
        }
    }
    const onChangeEstado = (e, data, index) => {
        console.log('e', e);
        let peds = [...pedidos];
        peds[index].anchorEl = null;
        setPedidos(peds);
        let opcion = e.target.name.split('-')[0];
        cambiarEstado(data.id, { campo: opcion, valor: e.target.checked });
    }
    const cambiarEstado = (id, estado) => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        setRequestEnProceso(true);
        var options = {
            url: Api.route('a/pedidos/' + id + '/estado'),
            method: 'POST',
            data: estado
        };
        Api.requestAxios(options, response => {
            Helper.log('cambiarEstado response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                buscarResultados(filtros);
            }
        }, error => {
            Helper.log('error cambiarEstado', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
        });
    }
    const getNombreCompleto = (user) => {
        let nombre = '--';
        if (user) {
            if (user.nombre) {
                nombre = user.nombre;
            }
            if (user.nombre && user.apellido) {
                nombre = user.nombre + ' ' + user.apellido;
            }
        }
        return nombre;
    }
    return (
        <div>
            <div className="w3-row">
                <div className="w3-col w3-right w3-right-align" style={{ width: '300px' }}>
                    <Button onClick={() => crear()} color="primary" variant="contained">Agregar pedido</Button>
                </div>
                <div className="w3-rest">
                    <h3>Pedidos</h3>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col w3-padding-small bs-bbox" style={{ width: '220px' }}>
                    <KeyboardDatePicker
                        cancelLabel="Cancelar"
                        clearLabel="Limpiar"
                        okLabel="Ok"
                        clearable
                        id="fecha-desde"
                        label="Fecha desde"
                        format="DD/MM/YYYY"
                        value={fechaDesde}
                        onChange={onChangeFechaDesde}
                        KeyboardButtonProps={{
                            'aria-label': 'Cambiar fecha',
                        }}
                    />
                </div>
                <div className="w3-col w3-padding-small bs-bbox" style={{ width: '220px' }}>
                    <KeyboardDatePicker
                        cancelLabel="Cancelar"
                        clearLabel="Limpiar"
                        okLabel="Ok"
                        clearable
                        id="fecha-hasta"
                        label="Fecha hasta"
                        format="DD/MM/YYYY"
                        value={fechaHasta}
                        onChange={onChangeFechaHasta}
                        KeyboardButtonProps={{
                            'aria-label': 'Cambiar fecha',
                        }}
                    />
                </div>
                {/* <div className="w3-col w3-padding-small bs-bbox" style={{ width: '220px' }}>
                    <FormControl variant="outlined" style={{ width: '100%', maxWidth: '420px', }}>
                        <InputLabel></InputLabel>
                        <OutlinedInputSmall
                            ref={searchInput}
                            placeholder="Buscar pedido"
                            id="input-search"
                            type="text"
                            fullWidth={true}
                            value={searchText}
                            onChange={(e) => cambioTextoBusqueda(e)}
                            disabled={(state.appReducer.actionsDisabled) ? true : false}
                            startAdornment={
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faSearch} size="sm" color={'#546E7A'} />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div> */}
            </div>
            {pedidos.length === 0 && !state.appReducer.loading && <div className="w3-block bs-bbox">
                <h3 className="w3-text-gray">No hay resultados</h3>
            </div>}
            {pedidos.length > 0 ? <div className="w3-block w3-margin-top">
                <table className="w3-table w3-border w3-bordered tabla w3-white">
                    <thead className="w3-border">
                        <tr>
                            <th>Id</th>
                            <th>Cliente</th>
                            <th>Fecha</th>
                            <th>Monto total</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="w3-border">
                        {pedidos.map((item, index) => <tr key={item.id} className={item.anchorEl ? 'w3-light-gray' : ''}>
                            <td>{item.id}</td>
                            <td>{getNombreCompleto(item.usuario)}</td>
                            <td>{Helper.datetimeToUiFormat(item.recibido)}</td>
                            <td>{Helper.numberUi(item.montoTotal)} Gs.</td>
                            <td style={{ width: '120px' }}>
                                <FontAwesomeIcon icon={faCircle} color={getEstado(item).color} />
                                <b> {getEstado(item).estado}</b>
                            </td>
                            <td style={{ width: '120px' }}>
                                <BotonAccionInfo variant="contained" color="primary" aria-controls={'menu-opciones-' + item.id} aria-haspopup="true" onClick={(e) => onClickOpciones(e, index)}>
                                    Opciones
                                </BotonAccionInfo>
                                <Menu
                                    id={'menu-opciones-' + item.id}
                                    anchorEl={item.anchorEl}
                                    keepMounted
                                    open={Boolean(item.anchorEl)}
                                    onClose={() => clickOpcion('cerrar', item, index)}>
                                    <MenuItem onClick={() => clickOpcion('ver', item, index)}>Ver</MenuItem>
                                    <MenuItem onClick={() => clickOpcion('editar', item, index)}>Editar</MenuItem>
                                    <MenuItem onClick={() => clickOpcion('eliminar', item, index)}>Eliminar</MenuItem>
                                    <hr />
                                    <MenuItem>
                                        <Switch checked={item.estados.procesando} onChange={(e) => onChangeEstado(e, item, index)} name={`procesando-${item.id}`} /><span>&nbsp;&nbsp;Procesando</span>
                                    </MenuItem>
                                    <MenuItem>
                                        <Switch checked={item.estados.listo} onChange={(e) => onChangeEstado(e, item, index)} name={`listo-${item.id}`} /><span>&nbsp;&nbsp;Listo</span>
                                    </MenuItem>
                                    <MenuItem>
                                        <Switch checked={item.estados.enCamino} onChange={(e) => onChangeEstado(e, item, index)} name={`enCamino-${item.id}`} /><span>&nbsp;&nbsp;En camino</span>
                                    </MenuItem>
                                    <MenuItem>
                                        <Switch checked={item.estados.entregado} onChange={(e) => onChangeEstado(e, item, index)} name={`entregado-${item.id}`} /><span>&nbsp;&nbsp;Entregado</span>
                                    </MenuItem>
                                    <MenuItem>
                                        <Switch checked={item.estados.pagado} onChange={(e) => onChangeEstado(e, item, index)} name={`pagado-${item.id}`} /><span>&nbsp;&nbsp;Pagado</span>
                                    </MenuItem>
                                    <MenuItem>
                                        <Switch checked={item.estados.finalizado} onChange={(e) => onChangeEstado(e, item, index)} name={`finalizado-${item.id}`} /><span>&nbsp;&nbsp;Finalizado</span>
                                    </MenuItem>




                                    {/* <MenuItem onClick={() => clickOpcion('estado-procesando', item, index)}>Marcar como en proceso</MenuItem>
                                    <MenuItem onClick={() => clickOpcion('estado-listo', item, index)}>Marcar como listo</MenuItem>
                                    <MenuItem onClick={() => clickOpcion('estado-enCamino', item, index)}>Marcar como en camino</MenuItem>
                                    <MenuItem onClick={() => clickOpcion('estado-entregado', item, index)}>Marcar como entregado</MenuItem>
                                    <MenuItem onClick={() => clickOpcion('estado-pagado', item, index)}>Marcar como pagado</MenuItem>
                                    <MenuItem onClick={() => clickOpcion('estado-finalizado', item, index)}>Marcar como finalizado</MenuItem> */}
                                </Menu>
                            </td>
                        </tr>)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="4" className="w3-small">
                                <div style={{ width: '100%', maxWidth: '300px', float: 'right' }}>
                                    <div className="w3-cell w3-cell-middle"><span>Filas por página </span></div>
                                    <div className="w3-cell">
                                        {paginador.selectCuantos.length > 0 ? <FormControl variant="outlined">
                                            <Select
                                                size="small"
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={paginador.cuantos}
                                                onChange={(e) => cambioCuantos(e)}
                                                input={<BootstrapInput />}>
                                                {paginador.selectCuantos.map((option) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
                                            </Select>
                                        </FormControl> : null}
                                    </div>
                                    <div className="w3-cell w3-cell-middle"><span>{filtros.desde + 1} - {((filtros.desde + paginador.cuantos) > paginador.numResultados) ? paginador.numResultados : filtros.desde + paginador.cuantos} de {paginador.numResultados} </span></div>
                                    <div className="w3-cell w3-cell-middle">
                                        <IconButton aria-label="Atrás" onClick={() => atras()} size="small" className={classes.margin} disabled={filtros.desde <= 1}>
                                            <FontAwesomeIcon icon={faChevronLeft} size="sm" />
                                        </IconButton>
                                        <IconButton aria-label="Siguiente" onClick={() => siguiente()} size="small" className={classes.margin} disabled={((filtros.desde + paginador.cuantos) > paginador.numResultados)}>
                                            <FontAwesomeIcon icon={faChevronRight} size="sm" />
                                        </IconButton>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div> : null}

            <SlidingPanel type={'right'} isOpen={panelVer} size={100}>
                <PanelContainer close={cancelar} panelLabel="Ver pedido" closeLabel="Pedidos">
                    <VerPedido cancelar={cancelar} pedido={pedido} configPedidos={configPedidos} />
                </PanelContainer>
            </SlidingPanel>

            <SlidingPanel type={'right'} isOpen={panelCrear} size={100}>
                <PanelContainer close={cancelar} panelLabel="Nuevo pedido" closeLabel="Pedidos">
                    <CrearPedido cancelar={cancelar} configPedidos={configPedidos} buscarResultados={buscarResultados} filtros={filtros} />
                </PanelContainer>
            </SlidingPanel>

            <SlidingPanel type={'right'} isOpen={panelEditar} size={100}>
                <PanelContainer close={cancelar} panelLabel="Edición pedido" closeLabel="Pedidos">
                    <EditarPedido cancelar={cancelar} pedido={pedido} configPedidos={configPedidos} buscarResultados={buscarResultados} filtros={filtros} />
                </PanelContainer>
            </SlidingPanel>

            {(modalEliminar && pedido) ? <div className="w3-modal w3-show">
                <div className="w3-modal-content w3-animate-zoom w3-round-large">
                    <div className="w3-row">
                        <div className="w3-col w3-right" style={{ width: '50px' }}>
                            <IconButton aria-label="delete" onClick={() => cancelar()}>
                                &nbsp;<FontAwesomeIcon icon={faTimes} />&nbsp;
                            </IconButton>
                        </div>
                        <div className="w3-rest w3-padding w3-center w3-text-gray">
                            <h2 style={{ margin: 0 }}><FontAwesomeIcon icon={faExclamationTriangle} /> Atención</h2>
                        </div>
                    </div>
                    <div className="w3-container w3-padding">
                        <p className="w3-large w3-margin w3-center">Estás a punto de eliminar el pedido con id: <b>{pedido.id}</b>. Ésta acción no se puede deshacer.</p>
                        <p className="w3-large w3-margin w3-center">¿Quieres continuar?</p>
                        <div className="w3-row w3-white">
                            <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                                <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                                    type="button"
                                    onClick={() => cancelar()}>No</button>
                            </div>
                            <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                                <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                                    type="button"
                                    onClick={() => confirmarEliminar()}>Si</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null}
        </div>
    );
};
export default withRouter(PedidosHistorial);