import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import { Helper } from 'utils';
import { Api } from 'services';
import { useSnackbar } from 'notistack';

import { faTags, faPowerOff, faUsers, faBoxes, faTachometerAlt, faUserAlt, faTasks, faChevronDown, faChevronUp, faHistory, faCaretRight, faPager } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { FaviconWeb } from 'assets/logos';
import Collapse from '@material-ui/core/Collapse';

import './menu.css';

const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        top: 80,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth,
        top: 65,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    avatar: {
        margin: 'auto',
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    nombreUsuario: {
        margin: '0px!important'
    },
    cargo: {
        color: '#546E7A',
        fontSize: '12px',
        margin: '0px'
    },
    subLista: {
        paddingLeft: theme.spacing(4),
    },
}));

const Menu = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const classes = useStyles();
    const [menuPedidosToggle, setMenuPedidosToggle] = useState(false);
    const [secciones, setSecciones] = useState([
        {id: 'inicio', nombre: 'Inicio'},
        {id: 'acerca-de', nombre: 'Acerca'},
        {id: 'contacto', nombre: 'Contacto'},
    ]);
    const [datosUsuario, setDatosUsuario] = useState({
        nombre: 'Nombre',
        tipo: 'Tipo usuario'
    });
    useEffect(() => {
        if (state.authReducer.usuario) {
            let nombre = '';
            if (state.authReducer.usuario.nombre) {
                nombre = state.authReducer.usuario.nombre;
            }
            if (state.authReducer.usuario.apellido) {
                nombre = nombre + ' ' + state.authReducer.usuario.apellido;
            }
            let tipo = '';
            if (state.authReducer.usuario.tipo) {
                tipo = state.authReducer.usuario.tipo;
            }
            setDatosUsuario({
                ...datosUsuario,
                nombre: nombre,
                tipo: tipo
            });
        }
        getSecciones();
    }, []);
    const getSecciones = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('a/ver-modificaciones'),
            method: 'POST',
            data: {}
        };
        Api.requestAxios(options, response => {
            Helper.log('getModificaciones response', response);
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                dispatch(AppActions.setModificaciones(response.data.datos));
            }
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
        }, error => {
            Helper.log('error getModificaciones', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer modificaciones', { variant: 'error' });
        });
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        dispatch(AppActions.setMenuAbierto(open));
    };
    const abrir = (path) => {
        dispatch(AppActions.setLastVisited(path));
        const { history } = props;
        if (history) history.push(path);
    };
    const cerrarSesion = () => {
        props.logout();
    };
    const menuToggle = (item) => {
        dispatch(AppActions.setSideMenuToggle({ ...state.appReducer.sideMenuToggle, [item]: !state.appReducer.sideMenuToggle[item] }));
    }
    const list = () => (
        <div id="menu-drawer" role="presentation" className="w3-padding bs-bbox"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}>
            <div className="bs-bbox">
                <div className="w3-block">
                    <div className="w3-padding w3-center">
                        {/*<Avatar alt={datosUsuario.nombre} src="assets/img/avatar-placeholder.png" className={classes.avatar} />*/}
						<img src={FaviconWeb} alt="Logo" className={classes.avatar} />
                        <Typography variant="h6" noWrap>{datosUsuario.nombre}</Typography>
                        <Typography variant="inherit" noWrap className={classes.cargo}>{datosUsuario.tipo.toUpperCase()}</Typography>
                    </div>
                </div>
                <Divider />
                {state.appReducer.sideMenuToggle ? <List>
                    <ListItem button onClick={() => abrir('/panel/dashboard')} className={'drawer-link' + ((props.history.location.pathname === '/panel/dashboard') ? ' active' : '')}>
                        <ListItemIcon><FontAwesomeIcon icon={faTachometerAlt} /></ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem button onClick={() => abrir('/panel/administradores')} className={'drawer-link' + ((props.history.location.pathname === '/panel/administradores') ? ' active' : '')}>
                        <ListItemIcon><FontAwesomeIcon icon={faUsers} /></ListItemIcon>
                        <ListItemText primary="Administradores" />
                    </ListItem>
                    <ListItem button onClick={() => abrir('/panel/categorias')} className={'drawer-link' + ((props.history.location.pathname === '/panel/categorias') ? ' active' : '')}>
                        <ListItemIcon><FontAwesomeIcon icon={faTags} /></ListItemIcon>
                        <ListItemText primary="Categorías" />
                    </ListItem>
                    <ListItem button onClick={() => abrir('/panel/productos')} className={'drawer-link' + ((props.history.location.pathname === '/panel/productos') ? ' active' : '')}>
                        <ListItemIcon><FontAwesomeIcon icon={faBoxes} /></ListItemIcon>
                        <ListItemText primary="Productos" />
                    </ListItem>
                    <ListItem button onClick={() => abrir('/panel/clientes')} className={'drawer-link' + ((props.history.location.pathname === '/panel/clientes') ? ' active' : '')}>
                        <ListItemIcon><FontAwesomeIcon icon={faUsers} /></ListItemIcon>
                        <ListItemText primary="Clientes" />
                    </ListItem>
                    {/* <ListItem button onClick={() => abrir('/panel/planillas')} className={'drawer-link' + ((props.history.location.pathname === '/panel/planillas') ? ' active' : '')}>
                        <ListItemIcon><FontAwesomeIcon icon={faUsers} /></ListItemIcon>
                        <ListItemText primary="Planillas" />
                    </ListItem> */}
                    <ListItem button onClick={() => menuToggle('pedidos')}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faTasks} />
                        </ListItemIcon>
                        <ListItemText primary="Pedidos" />
                        {state.appReducer.sideMenuToggle.pedidos ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                    </ListItem>
                    <Collapse in={state.appReducer.sideMenuToggle.pedidos} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button onClick={() => abrir('/panel/pedidos')} className={classes.subLista + ' drawer-link' + ((props.history.location.pathname === '/panel/pedidos') ? ' active' : '')}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faTasks} />
                                </ListItemIcon>
                                <ListItemText primary="Actuales" />
                            </ListItem>
                            <ListItem button onClick={() => abrir('/panel/pedidos-historial')} className={classes.subLista + ' drawer-link' + ((props.history.location.pathname === '/panel/pedidos-historial') ? ' active' : '')}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHistory} />
                                </ListItemIcon>
                                <ListItemText primary="Historial" />
                            </ListItem>
                        </List>
                    </Collapse>
                    { (state.appReducer && state.appReducer.modificaciones) ? <ListItem button onClick={() => menuToggle('secciones')}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faPager} />
                        </ListItemIcon>
                        <ListItemText primary="Secciones" />
                        {state.appReducer.sideMenuToggle.secciones ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                    </ListItem> : null }
                    { (state.appReducer && state.appReducer.modificaciones) ? <Collapse in={state.appReducer.sideMenuToggle.secciones} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            { state.appReducer.modificaciones.map((seccion) => <ListItem key={seccion.id} button onClick={() => abrir('/panel/secciones/'+seccion.id)} className={classes.subLista + ' drawer-link' + ((props.history.location.pathname === '/panel/secciones/'+seccion.id) ? ' active' : '')}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faCaretRight} />
                                </ListItemIcon>
                                <ListItemText primary={seccion.nombre} />
                            </ListItem>)}
                        </List>
                    </Collapse> : null }
					{/*
                    <ListItem button onClick={() => abrir('/panel/perfil')} className={'drawer-link' + ((props.history.location.pathname === '/panel/perfil') ? ' active' : '')}>
                        <ListItemIcon><FontAwesomeIcon icon={faUserAlt} /></ListItemIcon>
                        <ListItemText primary="Mi perfil" />
                    </ListItem>
					*/}
                </List> : null }
                <Divider />
                <List>
                    <ListItem button onClick={() => cerrarSesion()}>
                        <ListItemIcon><FontAwesomeIcon icon={faPowerOff} /></ListItemIcon>
                        <ListItemText primary="Cerrar sesión" />
                    </ListItem>
                </List>
            </div>
        </div>
    );
    return (
        <Drawer className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}>
            {list()}
        </Drawer>
    );
}
export default withRouter(Menu);