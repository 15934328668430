import React from 'react';
import './switch.css';
function Switch(props) {
    const handleChange = (e) => {
        if (!props.disabled) {
            props.onChange(e);
        }
    };
    return (
        <label className="switch w3-right">
            <input type="checkbox"
                name={props.name}
                disabled={(props.disabled === true) ? true : false}
                checked={(props.checked === true) ? true : false}
                onChange={handleChange}/>
            <span className="switch-slider round"></span>
        </label>
    );
}
export default Switch;