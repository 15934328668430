//js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import { useStore } from 'store';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { StateProvider, DispatchProvider } from 'context';
import { createBrowserHistory } from "history";
import CONFIG from "config";

//css
import 'styles/index.css';
import 'styles/animate.css';
import 'styles/w3.css';
import 'styles/w3-theme.css';
import 'styles/style.css';
import 'styles/colors.css';
//main component
import App from 'app/app';

const theme = createTheme({
	palette: {
	  primary: {
		main: '#9999ff',
		contrastText: '#fff'
	  },
	  secondary: {
		main: '#6C757D'
	  }
	}
});

const history = createBrowserHistory();
function Root() {
    const { state, dispatch } = useStore();
    return (
        <DispatchProvider value={dispatch}>
            <StateProvider value={state}>
                <App />
            </StateProvider>
        </DispatchProvider>
    );
}
ReactDOM.render(
    <Router basename={CONFIG.BASENAME} history={history}>
		<MuiThemeProvider theme={theme}>
			<Root />
		</MuiThemeProvider>
    </Router>,
    document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();