import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './clientes.css';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const EditarCliente = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [cliente, setCliente] = useState({
        ...props.cliente,
        email: (props.cliente && props.cliente.email) ? props.cliente.email : '',
        ruc: (props.cliente && props.cliente.ruc) ? props.cliente.ruc : '',
        contrasena: '',
        reContrasena: ''
    });
    const [errores, setErrores] = useState({});
    const [hayCambios, setHayCambios] = useState(false);
    const [noCoincidenPass, setNoCoincidenPass] = useState(false);
    const [cambiarContrasena, setCambiarContrasena] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [rePasswordVisible, setRePasswordVisible] = useState(false);
    const original = { ...props.cliente };
    const cerrar = () => {
        props.cancelar();
        props.buscarResultados(props.filtros);
    }
    useEffect(() => {
        const { contrasena, reContrasena } = cliente;
        setHayCambios(false);
        if (cambiarContrasena) {
            if (reContrasena && contrasena) {
                if (reContrasena === contrasena) {
                    setNoCoincidenPass(false);
                    setHayCambios(true);
                } else {
                    setNoCoincidenPass(true);
                }
            }
        }
        if ( cliente.usuario && cliente.usuario !== original.usuario ) {
            setHayCambios(true);
        }
        if ( cliente.nombre &&  cliente.nombre !== original.nombre ) {
            setHayCambios(true);
        }
        if ( cliente.apellido &&  cliente.apellido !== original.apellido ) {
            setHayCambios(true);
        }
        if ( cliente.email &&  cliente.email !== original.email ) {
            setHayCambios(true);
        }
        if ( cliente.ruc &&  cliente.ruc !== original.ruc ) {
            setHayCambios(true);
        }
    }, [cliente]);
    useEffect(() => {
        setCliente({ ...cliente, contrasena: '', reContrasena: '' });
    }, [cambiarContrasena]);
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setCliente({ ...cliente, [name]: value });
        setErrores({ ...errores, [name]: false });
    };
    const guardar = () => {
        let todoBien = true;
        let data = {};
        let errores = {};
        const { id, nombre, apellido, email, ruc, contrasena, reContrasena } = cliente;        
        //nombre
        if (nombre) {
            if (nombre !== original.nombre) {
                data.nombre = nombre;
            }
            errores.nombre = false;
        } else {
            todoBien = false;
            errores.nombre = true;
        }
        //apellido
        if (apellido) {
            if (apellido !== original.apellido) {
                data.apellido = apellido;
            }
            errores.apellido = false;
        } else {
            todoBien = false;
            errores.apellido = true;
        }
        //email
        if (email) {
            if (email !== original.email) {
                data.email = email;
            }
            errores.email = false;
        } else {
            todoBien = false;
            errores.email = true;
        }
        //ruc
        if (ruc) {
            if (ruc !== original.ruc) {
                data.ruc = ruc;
            }
            errores.ruc = false;
        } else {
            // todoBien = false;
            // errores.ruc = true;
        }
        //contrasena
        if (cambiarContrasena) {
            if (contrasena) {
                data.contrasena = contrasena;
                errores.contrasena = false;
            } else {
                todoBien = false;
                errores.contrasena = true;
            }
            //reContrasena
            if (reContrasena) {
                if (reContrasena === contrasena) {
                    errores.reContrasena = false;
                } else {
                    todoBien = false;
                    errores.reContrasena = true;
                }
            } else {
                todoBien = false;
                errores.reContrasena = true;
            }
        }
        setErrores(errores);
        if (todoBien) {
            data.usuarioId = id;
            dispatch(AppActions.setLoading(true));
            dispatch(AppActions.setActionsDisabled(true));
            var options = {
                url: Api.route('a/clientes/' + id),
                data: data,
                method: 'PUT'
            };
            Api.requestAxios(options, response => {
                Helper.log('edicionCliente response', response);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                if (response.data.error) {
                    enqueueSnackbar(response.data.msg, { variant: 'error' });
                } else {
                    enqueueSnackbar(response.data.msg, { variant: 'success' });
                    cerrar();
                }
            }, error => {
                Helper.log('error edicionCliente', error);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                enqueueSnackbar('Error al subir nuevo cliente', { variant: 'error' });
            });
        }
    };
    return (
        <div>
            { cliente ? <div className="w3-padding-top bs-bbox">
                <form className="w3-margin-bottom w3-padding" id="formCrear">
                    <div className="w3-row">
                        <div className="w3-col l4 w3-padding">
                            <p>Estás editando los datos de un cliente</p>
                        </div>
                        <div className="w3-col l8 w3-padding bs-bbox">
                            <div className="w3-white bs-bbox w3-padding-large">

                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.nombre}
                                    name="nombre"
                                    type="text"
                                    label="Nombre"
                                    value={cliente.nombre}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br />

                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.apellido}
                                    name="apellido"
                                    type="text"
                                    label="Apellido"
                                    value={cliente.apellido}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br />

                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.email}
                                    name="email"
                                    type="email"
                                    label="Email"
                                    value={cliente.email}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br /><br />

                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.ruc}
                                    name="ruc"
                                    type="text"
                                    label="RUC"
                                    value={cliente.ruc}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br /><br />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={cambiarContrasena}
                                            onChange={event => setCambiarContrasena(event.target.checked)}
                                            name="cambiarContrasena"
                                            color="primary"
                                        />
                                    }
                                    label="Cambiar contraseña"
                                /><br />

                                {cambiarContrasena ? <div>

                                    <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                                        <InputLabel htmlFor="input-password">Contraseña*</InputLabel>
                                        <OutlinedInput
                                            error={errores.contrasena}
                                            id="input-password"
                                            type={passwordVisible ? 'text' : 'password'}
                                            value={cliente.contrasena}
                                            onChange={handleChange}
                                            name="contrasena"
                                            disabled={(state.appReducer.actionsDisabled) ? true : false}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setPasswordVisible((visible) => !visible)}
                                                        onMouseDown={e => e.preventDefault()}
                                                        edge="end"
                                                    >
                                                        {passwordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} size="sm" />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={85}
                                        />
                                    </FormControl><br /><br /><br />

                                    <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                                        <InputLabel htmlFor="input-repassword">Repetir contraseña*</InputLabel>
                                        <OutlinedInput
                                            error={errores.reContrasena}
                                            id="input-repassword"
                                            type={passwordVisible ? 'text' : 'password'}
                                            value={cliente.reContrasena}
                                            onChange={handleChange}
                                            name="reContrasena"
                                            disabled={(state.appReducer.actionsDisabled) ? true : false}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setRePasswordVisible((visible) => !visible)}
                                                        onMouseDown={e => e.preventDefault()}
                                                        edge="end"
                                                    >
                                                        {rePasswordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} size="sm" />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={145}
                                        />
                                    </FormControl><br /><br /><br />
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </form>
                <Divider />
                <div className="w3-row w3-white">
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                            type="button"
                            onClick={() => props.cancelar()}>Cancelar</button>
                    </div>
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                            type="button"
                            disabled={!hayCambios}
                            onClick={() => guardar()}>Guardar</button>
                    </div>
                </div>
            </div> : null }
        </div>
    );
};
export default withRouter(EditarCliente);