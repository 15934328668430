import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

import './clientes.css';
import { useSnackbar } from 'notistack';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

const CrearCliente = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [cliente, setCliente] = useState({
        abm: 'nuevo',
        usuario: '',
        nombre: '',
        apellido: '',
        email: '',
        ruc: '',
        contrasena: '',
        reContrasena: ''
    });
    const [errores, setErrores] = useState({});
    const [noCoincidenPass, setNoCoincidenPass] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [rePasswordVisible, setRePasswordVisible] = useState(false);
    const cerrar = () => {
        props.cancelar();
        props.getClientes();
    }
    useEffect(() => {
        console.log('cliente', cliente);
    }, []);
    useEffect(() => {
        const { contrasena, reContrasena } = cliente;
        if (reContrasena && contrasena) {
            if (reContrasena === contrasena) {
                setNoCoincidenPass(false);
            } else {
                setNoCoincidenPass(true);
            }
        }
    }, [cliente]);
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setCliente({ ...cliente, [name]: value });
        setErrores({ ...errores, [name]: false });
    };
    const guardar = () => {
        let todoBien = true;
        let data = {};
        let errores = {};
        const { usuario, nombre, apellido, email, ruc, contrasena, reContrasena } = cliente;
        //usuario
        if (usuario) {
            data.usuario = usuario;
            errores.usuario = false;
        } else {
            todoBien = false;
            errores.usuario = true;
        }
        //nombre
        if (nombre) {
            data.nombre = nombre;
            errores.nombre = false;
        } else {
            todoBien = false;
            errores.nombre = true;
        }
        //apellido
        if (apellido) {
            data.apellido = apellido;
            errores.apellido = false;
        } else {
            todoBien = false;
            errores.apellido = true;
        }
        //email
        if (email) {
            data.email = email;
            errores.email = false;
        } else {
            todoBien = false;
            errores.email = true;
        }
        //ruc
        if (ruc) {
            data.ruc = ruc;
            errores.ruc = false;
        } else {
            // todoBien = false;
            // errores.ruc = true;
        }
        //contrasena
        if (contrasena) {
            data.contrasena = contrasena;
            errores.contrasena = false;
        } else {
            todoBien = false;
            errores.contrasena = true;
        }
        //reContrasena
        if (reContrasena) {
            if (reContrasena === contrasena) {
                setNoCoincidenPass(false);
                errores.reContrasena = false;
            } else {
                setNoCoincidenPass(true);
                todoBien = false;
                errores.reContrasena = true;
            }
        } else {
            todoBien = false;
            errores.reContrasena = true;
        }
        setErrores(errores);
        console.log('todoBien', todoBien);
        console.log('data', data);
        if (todoBien) {
            dispatch(AppActions.setLoading(true));
            dispatch(AppActions.setActionsDisabled(true));
            var options = {
                url: Api.route('a/clientes'),
                data: data,
                method: 'POST'
            };
            Api.requestAxios(options, response => {
                Helper.log('nuevoCliente response', response);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                if (response.data.error) {
                    enqueueSnackbar(response.data.msg, { variant: 'error' });
                } else {
                    enqueueSnackbar(response.data.msg, { variant: 'success' });
                    cerrar();
                }
            }, error => {
                Helper.log('error nuevoCliente', error);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                enqueueSnackbar('Error al subir nuevo cliente', { variant: 'error' });
            });
        }
    };
    return (
        <div>
            <div className="w3-padding-top bs-bbox">
                <form className="w3-margin-bottom w3-padding">
                    <div className="w3-row">
                        <div className="w3-col l4 w3-padding">
                            <p>Completá los datos y luego presioná el botón "Guardar"</p>
                        </div>
                        <div className="w3-col l8 w3-padding bs-bbox">
                            <div className="w3-white bs-bbox w3-padding-large">
                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.usuario}
                                    name="usuario"
                                    type="text"
                                    label="Usuario"
                                    value={cliente.usuario}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br />

                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.nombre}
                                    name="nombre"
                                    type="text"
                                    label="Nombre"
                                    value={cliente.nombre}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br />

                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.apellido}
                                    name="apellido"
                                    type="text"
                                    label="Apellido"
                                    value={cliente.apellido}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br /><br />

                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.email}
                                    name="email"
                                    type="email"
                                    label="Email"
                                    value={cliente.email}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br /><br />

                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.ruc}
                                    name="ruc"
                                    type="text"
                                    label="RUC"
                                    value={cliente.ruc}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br /><br />


                                <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                                    <InputLabel htmlFor="input-password">Contraseña*</InputLabel>
                                    <OutlinedInput
                                        error={errores.contrasena}
                                        id="input-password"
                                        type={passwordVisible ? 'text' : 'password'}
                                        value={cliente.contrasena}
                                        onChange={handleChange}
                                        name="contrasena"
                                        disabled={(state.appReducer.actionsDisabled) ? true : false}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setPasswordVisible((visible) => !visible)}
                                                    onMouseDown={e => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {passwordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} size="sm" />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={85}
                                    />
                                </FormControl><br /><br /><br />

                                <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                                    <InputLabel htmlFor="input-repassword">Repetir contraseña*</InputLabel>
                                    <OutlinedInput
                                        error={errores.reContrasena}
                                        id="input-repassword"
                                        type={passwordVisible ? 'text' : 'password'}
                                        value={cliente.reContrasena}
                                        onChange={handleChange}
                                        name="reContrasena"
                                        disabled={(state.appReducer.actionsDisabled) ? true : false}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setRePasswordVisible((visible) => !visible)}
                                                    onMouseDown={e => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {rePasswordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} size="sm" />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={145}
                                    />
                                </FormControl><br /><br /><br />
                            </div>
                        </div>
                    </div>
                </form>
                <Divider />
                <div className="w3-row w3-white">
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                            type="button"
                            onClick={() => props.cancelar()}>Cancelar</button>
                    </div>
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                            type="button"
                            onClick={() => guardar()}>Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(CrearCliente);