// Initial State
const authInitialState = {
    loggedIn: false,
    hash: null,
    loginProvider: null,
    loginToken: null,
    usuario: null,
    loginFormVisible: false
};
// Reducers (Modifies The State And Returns A New State)
const authReducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            return {
                ...authInitialState
            };
        }
        case 'LOGGED_IN': {
            return {
                ...state,
                loggedIn: action.loggedIn,
            }
        }
        case 'HASH': {
            return {
                ...state,
                hash: action.hash,
            }
        }
        case 'LOGIN_PROVIDER': {
            return {
                ...state,
                loginProvider: action.loginProvider,
            }
        }
        case 'LOGIN_TOKEN': {
            return {
                ...state,
                loginToken: action.loginToken,
            }
        }
        case 'USUARIO': {
            return {
                ...state,
                usuario: action.usuario,
            }
        }      
        case 'CONFIG': {
            return {
                ...state,
                config: action.config,
            }
        }
        case 'LOGIN_FORM': {
            return {
                ...state,
                loginForm: action.loginForm,
            }
        }
        default: {
            return state;
        }
    }
};
// Exports
export { authReducer, authInitialState };