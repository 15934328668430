import CONFIG from "config";
import axios from './axios';
import { AppStorage } from 'utils';

const Api = {
    paths: {
        login: 'p/login',
        //PERFIL
        getPerfil: 'u/perfil',
        putPerfil: 'u/perfil',
        //REGISTRO
        //datos básicos
        getConfigRegistroTransportista: 'p/config-datos-transportista',
        postNuevoTransportista: 'p/nuevo-transportista',
        putTransportista: 't/mi-perfil',
        postTransportistaFoto: 't/mi-perfil/foto',
        //cédula de identidad
        putTransportistaCi: 't/mi-perfil/ci',
        postTransportistaCiFrente: 't/mi-perfil/ci/frente',
        postTransportistaCiDorso: 't/mi-perfil/ci/dorso',
        //licencia de conducir
        putTransportistaLc: 't/mi-perfil/licencia-conducir',
        postTransportistaLcFrente: 't/mi-perfil/licencia-conducir/frente',
        postTransportistaLcDorso: 't/mi-perfil/licencia-conducir/dorso'
    },
    routeBase: function () {
        return CONFIG.URL_BASE;
    },
    route: function (sections) {
        let sectionsString = "";
        let url = null;
        if (Array.isArray(sections)) {
            sectionsString = sections.join('/');
        } else {
            sectionsString = sections;
        }
        url = CONFIG.URL_BASE + sectionsString;
        return url;
    },
    testRoute: function (sections) {
        let sectionsString = "";
        let url = null;
        if (Array.isArray(sections)) {
            sectionsString = sections.join('/');
        } else {
            sectionsString = sections;
        }
        url = CONFIG.URL_PRUEBA + sectionsString;
        return url;
    },
    prepareConfig: function (opts, tipo) {
        let options = { ...opts};
        const state = AppStorage.getStore(CONFIG.ID);
        let config = {};
        let headers = {
            crossDomain: true
        };
        let loginProvider = 'ingalca';
        if (options.loginProvider) {
            loginProvider = options.loginProvider;
        } else if (state.authReducer.loginProvider) {
            loginProvider = state.authReducer.loginProvider;
        }
        console.log('options', options);
        const slug = (state.authReducer.organizacion) ? state.authReducer.organizacion.slug : null;
        if (loginProvider === 'ingalca') {
            if (state.authReducer.hash) {
                headers.Authorization = "Basic " + state.authReducer.hash;
                config.withCredentials = true;
            } else if (options.hash) {
                headers.Authorization = "Basic " + options.hash;
                config.withCredentials = true;
            }

            if (options.method === 'GET' || options.method === 'DELETE') {
                if (options.method === 'GET') {
                    headers['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
                    headers['Cache-Control'] = 'no-cache';
                    headers['Pragma'] = 'no-cache';
                }
                if (options.url.indexOf('?') === -1) {
                    options.url += '?';
                } else if (options.url.substr(options.url.length - 1) !== '&') {
                    options.url += '&';
                }
                options.url += 'loginProvider=' + loginProvider;
                if (slug) {
                    if (options.url.indexOf('?') === -1) {
                        options.url += '?';
                    } else if (options.url.substr(options.url.length - 1) !== '&') {
                        options.url += '&';
                    }
                    options.url += 'organizacion=' + slug;
                }
            } else {
                if (!options.data) {
                    options.data = {};
                }
                options.data.loginProvider = loginProvider;
                if (slug) {
                    options.data.organizacion = slug;
                }
            }
        } else if (loginProvider === 'facebook' || loginProvider === 'linkedin') {
            if (options.method === 'GET' || options.method === 'DELETE') {
                if (options.method === 'GET') {
                    headers['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
                    headers['Cache-Control'] = 'no-cache';
                    headers['Pragma'] = 'no-cache';
                }
                if (options.url.indexOf('?') === -1) {
                    options.url += '?';
                } else if (options.url.substr(options.url.length - 1) !== '&') {
                    options.url += '&';
                }
                options.url += 'loginProvider=' + loginProvider;

                if (options.url.indexOf('?') === -1) {
                    options.url += '?';
                } else if (options.url.substr(options.url.length - 1) !== '&') {
                    options.url += '&';
                }
                options.url += 'token=' + state.authReducer.loginToken;
                if (slug) {
                    if (options.url.indexOf('?') === -1) {
                        options.url += '?';
                    } else if (options.url.substr(options.url.length - 1) !== '&') {
                        options.url += '&';
                    }
                    options.url += 'organizacion=' + slug;
                }
            } else {
                if (!options.data) {
                    options.data = {};
                }
                options.data.loginProvider = loginProvider;
                if (options.token) {
                    options.data.token = options.token;
                } else if (state.authReducer.loginToken) {
                    options.data.token = state.authReducer.loginToken;
                }
                if (slug) {
                    options.data.organizacion = slug;
                }
            }
        }
        if (tipo === 'fetch') {
            config.method = options.method;
            config.url = options.url;
            if (options.data) {
                config.body = options.method === 'PUT' || options.method === 'POST' ? JSON.stringify(options.data) : options.data;
                config.headers = headers;
            }

        } else if (tipo === 'axios') {
            config.method = options.method.toLowerCase();
            config.url = options.url;
            if (options.data) {
                config.data = options.method === 'PUT' || options.method === 'POST' ? options.data : options.data;
                config.headers = headers;
            }
            config.headers = headers;
        }
        console.log('Request config', config);
        return config;
    },
    request: function (options, successCallback, errorCallback) {
        const config = this.prepareConfig(options, 'fetch');
        return fetch(config.url, config).then((res) => res.json()).then(successCallback).catch(errorCallback);
    },
    requestAxios: function (options, successCallback, errorCallback) {
        let config = this.prepareConfig(options, 'axios');
        return axios(config).then(successCallback).catch(errorCallback);
    },
    uploadImageAxios: function (ruta, clave, archivo, params, successCallback, errorCallback) {
        const state = AppStorage.getStore(CONFIG.ID);
        const slug = (state.authReducer.organizacion) ? state.authReducer.organizacion.slug : null;
        if (slug) {
            params.organizacion = slug;
        }
        let loginProvider = 'ingalca';
        let headers = {
            crossDomain: true,
            'Content-Type': 'multipart/form-data'
        };
        if (state.authReducer.loginProvider) {
            loginProvider = state.authReducer.loginProvider;
        }
        params.loginProvider = loginProvider;
        let config = {
            url: ruta,
            method: 'post',
        };
        if (loginProvider === 'ingalca') {
            if (state.authReducer.hash) {
                headers.Authorization = "Basic " + state.authReducer.hash;
                config.withCredentials = true;
            }
        } else if (loginProvider === 'facebook') {
            params.token = state.authReducer.loginToken;
        } else if (loginProvider === 'google') {
            params.token = state.authReducer.loginToken;
        } else if (loginProvider === 'linkedin') {
            params.token = state.authReducer.loginToken;
        }
        config.headers = headers;
        const formData = new FormData();
        formData.append('data', JSON.stringify(params));
        
        // let imageData = {
        //     uri: archivo.path,
        //     type: archivo.mime,
        //     name: archivo.path.split('\\').pop().split('/').pop(),
        // };
        // console.log('imageData', imageData);
        // formData.append(clave, imageData);    
        formData.append(clave, archivo);     
        // console.log('formData.getAll()', formData.getAll());
        config.data = formData;
        console.log('Request config', config);
        return axios(config).then(successCallback).catch(errorCallback);
    },
    uploadFileAxios: function (ruta, clave, archivo, params, successCallback, errorCallback) {
        const state = AppStorage.getStore(CONFIG.ID);
        const slug = (state.authReducer.organizacion) ? state.authReducer.organizacion.slug : null;
        if (slug) {
            params.organizacion = slug;
        }
        let loginProvider = 'ingalca';
        let headers = {
            crossDomain: true,
            'Content-Type': 'multipart/form-data'
        };
        if (state.authReducer.loginProvider) {
            loginProvider = state.authReducer.loginProvider;
        }
        params.loginProvider = loginProvider;
        let config = {
            url: ruta,
            method: 'post',
        };
        if (loginProvider === 'ingalca') {
            if (state.authReducer.hash) {
                headers.Authorization = "Basic " + state.authReducer.hash;
                config.withCredentials = true;
            }
        } else if (loginProvider === 'facebook') {
            params.token = state.authReducer.loginToken;
        } else if (loginProvider === 'google') {
            params.token = state.authReducer.loginToken;
        } else if (loginProvider === 'linkedin') {
            params.token = state.authReducer.loginToken;
        }
        config.headers = headers;
        const formData = new FormData();
        formData.append('data', JSON.stringify(params));
        
        // let imageData = {
        //     uri: archivo.path,
        //     type: archivo.mime,
        //     name: archivo.path.split('\\').pop().split('/').pop(),
        // };
        // console.log('imageData', imageData);
        // formData.append(clave, imageData);    
        formData.append(clave, archivo);     
        // console.log('formData.getAll()', formData.getAll());
        config.data = formData;
        console.log('Request config', config);
        return axios(config).then(successCallback).catch(errorCallback);
    },
    uploadImageFromWeb: function (ruta, clave, archivo, params, successCallback, errorCallback) {
        const state = AppStorage.getStore(CONFIG.ID);
        let loginProvider = 'ingalca';
        let headers = {
            crossDomain: true,
            'Content-Type': undefined
        };
        if (state.authReducer.loginProvider) {
            loginProvider = state.authReducer.loginProvider;
        }
        params.loginProvider = loginProvider;
        let options = {};
        if (loginProvider === 'ingalca') {
            if (state.authReducer.hash) {
                headers.Authorization = "Basic " + state.authReducer.hash;
                options.withCredentials = true;
            }
        } else if (loginProvider === 'facebook') {
            params.token = state.authReducer.loginToken;
        } else if (loginProvider === 'google') {
            params.token = state.authReducer.loginToken;
        } else if (loginProvider === 'linkedin') {
            params.token = state.authReducer.loginToken;
        }
        options.headers = headers;
        options.params = params;
        //options.transformRequest = angular.identity;
        console.log('Request options', options);
        let fd = new FormData();
        fd.append(clave, archivo); console.log('archivo', archivo);
        fd.append('descripcion', params.descripcion);
        options.body = fd;
        return fetch(options.url, options).then((res) => res.json()).then(successCallback).catch(errorCallback);
    },
};
export default Api;