import React, { useState, useEffect } from 'react';
import { Api } from 'services';
import { withRouter } from "react-router-dom";
import './jerarquias.css';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
const VerJerarquia = (props) => {
    const [jerarquia, setJerarquia] = useState({ ...props.jerarquia, jerarquiaPapaNombre: '' });
    // const [selectOptions, setSelectOptions] = useState([]);
    useEffect(() => {
        if (props.configJerarquias && props.configJerarquias.jerarquias && props.configJerarquias.jerarquias.length > 0) {
            let options = [];
            for (let i in props.configJerarquias.jerarquias) {
                options.push({ value: props.configJerarquias.jerarquias[i].id, label: props.configJerarquias.jerarquias[i].nombre });
            }
            // setSelectOptions(options);
        }
        if (jerarquia.jerarquiaPapa && jerarquia.jerarquiaPapa.id) {
            setJerarquia({ ...jerarquia, jerarquiaPapaNombre: jerarquia.jerarquiaPapa.nombre });
        }
    }, []);
    return (
        <div>
            <div className="w3-padding-top bs-bbox">
                <form className="w3-margin-bottom w3-padding">
                    <div className="w3-row">
                        <div className="w3-col l4 w3-padding">
                            <p>Estás viendo los datos de una jerarquía</p>
                        </div>
                        <div className="w3-col l8 w3-padding bs-bbox">
                            <div className="w3-white bs-bbox w3-padding-large">
                                {(jerarquia && jerarquia.multimedia && jerarquia.multimedia.url) ? <div className="w3-col bs-bbox" style={{ width: '200px', padding: '4px' }}>
                                    <div className="w3-border w3-round" style={{
                                        width: '100%',
                                        paddingTop: '100%',
                                        backgroundColor: '#F4F6F8',
                                        backgroundPosition: 'center',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundImage: "url('" + Api.route(jerarquia.multimedia.url) + "')"
                                    }}>
                                    </div>
                                </div> : null}

                                <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                    type="text"
                                    label="Nombre"
                                    value={jerarquia.nombre}
                                    disabled={true} /><br /><br />

                                <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                    type="number"
                                    label="Orden"
                                    value={jerarquia.orden}
                                    disabled={true} /><br /><br />

                                <img src={jerarquia.fotoUrl} alt="" />

                                <TextField required fullWidth margin="normal" variant="outlined" size="small"
                                    type="text"
                                    label="Categoría padre"
                                    value={jerarquia.jerarquiaPapaNombre}
                                    disabled={true} /><br /><br />
                            </div>
                        </div>
                    </div>
                </form>
                <Divider />
                <div className="w3-row w3-white">
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                            type="button"
                            onClick={() => props.cancelar()}>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(VerJerarquia);