import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import './pedidos.css';

import Divider from '@material-ui/core/Divider';

const VerPedido = (props) => {
    const [pedido, setPedido] = useState(props.pedido);
    useEffect(() => {
    }, []);
    return (
        <div>
            <div className="w3-padding-top bs-bbox">
                <form className="w3-margin-bottom w3-padding">
                    <div className="w3-row">
                        <div className="w3-col l4 w3-padding">
                            <p>Estás viendo los datos de un pedido</p>
                        </div>
                        <div className="w3-col l8 w3-padding bs-bbox">
                            <div className="w3-white bs-bbox w3-padding-large">
                                


                            </div>
                        </div>
                    </div>
                </form>
                <Divider />
                <div className="w3-row w3-white">
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                            type="button"
                            onClick={() => props.cancelar()}>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(VerPedido);