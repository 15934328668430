import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import CONFIG from "config";
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { DispatchContext } from 'context';
// import { StateContext, DispatchContext } from 'context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import './pedidos.css';
import { useSnackbar } from 'notistack';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";

import { Colors } from 'styles';


// import { Boton, BotonInfo, BotonSuccess, BotonDefault, IconButtonSmall, IconButtonExtraSmall } from 'components/material-ui';

const RadioTheme = withStyles({
    root: {
        color: Colors.THEME,
        '&$checked': {
            color: Colors.THEME_D1,
        },
    }
})((props) => <Radio color="default" {...props} />);


Geocode.setApiKey("AIzaSyCaw8ahy7ztFKt6aq9kbqVlM1Bk_YmUU9s");
Geocode.setLanguage("en");
const VerPedido = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    // const state = useContext(StateContext);


    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [entrega, setEntrega] = useState('tienda');
    const [precioDelivery, setPrecioDelivery] = useState(20000);
    const [moneda, setMoneda] = useState('₲');
    // const [miUbicacionHabilitada, setMiUbicacionHabilitada] = useState(false);
    const [lugares, setLugares] = useState(null);
    const [mapas, setMapas] = useState(null);
    const [mapa, setMapa] = useState(null);
    // const [datosUbicacion, setDatosUbicacion] = useState(null);
    // const [tabUbicacion, setTabUbicacion] = useState('mapa');

    const [pedido, setPedido] = useState(null);
    useEffect(() => {
        getPedido();
    }, []);
    useEffect(() => {
        console.log('pedido', pedido);
    }, [pedido]);

    useEffect(() => {
        if (pedido) {
            let sTotal = 0;
            for (var i in pedido.items) {
                sTotal = sTotal + (pedido.items[i].monto * pedido.items[i].cantidad);
            }
            let tl = sTotal;
            if (entrega === 'delivery') {
                tl = tl + precioDelivery;
            }
            setSubTotal(sTotal);
            setTotal(tl);
        }
    }, [pedido, entrega]);

    const inicializarPedido = (datos) => {
        if (datos.puntoEntrega) {
            setEntrega('delivery');
            if (typeof datos.puntoEntrega.lat !== 'undefined' && datos.puntoEntrega.lat !== null && typeof datos.puntoEntrega.lon !== 'undefined' && datos.puntoEntrega.lon !== null) {
                // setCenter({
                //     lat: response.data.datos.puntoEntrega.lat,
                //     lng: response.data.datos.puntoEntrega.lon
                // });  
                const coords = {
                    lat: parseFloat(datos.puntoEntrega.lat),
                    lng: parseFloat(datos.puntoEntrega.lon)
                }
                if (mapas) {
                    const bounds = new mapas.LatLngBounds();
                    bounds.extend(new mapas.LatLng(coords.lat, coords.lng - 0.005562184202671051,));
                    bounds.extend(new mapas.LatLng(coords.lat, coords.lng + 0.005562184202671051,));
                    bounds.extend(new mapas.LatLng(coords.lat, coords.lng,));
                    mapa.fitBounds(bounds);
                }
                
            }
        }
        setPedido(datos);
    }

    const getPedido = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('a/pedidos/' + props.pedido.id),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getPedido response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                if (response.data && response.data.datos) {
                    inicializarPedido(response.data.datos);
                }
            }
        }, error => {
            Helper.log('error getPedido', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer pedido', { variant: 'error' });
        });
    };

    // const elegirUbicacion = (ubcn) => {
    //     setDatosUbicacion({
    //         id: ubcn.id,
    //         lat: parseFloat(ubcn.lat),
    //         lng: parseFloat(ubcn.lon),
    //         direccion: ubcn.direccion,
    //         barrio: ubcn.barrio,
    //         ciudad: ubcn.ciudad,
    //         observaciones: ubcn.observaciones,
    //     });
    // }

    // const onChangeEntrega = (event) => {
    //     setEntrega(event.target.value);
    //     if (event.target.value === 'delivery') {
    //         navigator.geolocation.getCurrentPosition(successGetUbicacion, errorGetUbicacion, optionsGetUbicacion);
    //         setTabUbicacion('mapa');
    //     }
    // };
    // const handleChangeDireccion = (event) => {
    //     setDatosUbicacion({ ...datosUbicacion, direccion: event.target.value });
    // }
    //mapa
    const Marker = () => <FontAwesomeIcon icon={faMapMarkerAlt} color={Colors.THEME} size="3x" style={{
        position: "absolute",
        transform: "translate(-50%, -100%)"
    }} />;
    const center = {
        lat: -25.276750711185308,
        lng: -57.63522231951356
    };
    const zoom = 15;
    // var optionsGetUbicacion = {
    //     enableHighAccuracy: true,
    //     timeout: 5000,
    //     maximumAge: 0
    // };
    // function successGetUbicacion(pos) {
    //     setMiUbicacionHabilitada(true);
    // };
    // function errorGetUbicacion(err) {
    //     setMiUbicacionHabilitada(false);
    //     console.warn('ERROR(' + err.code + '): ' + err.message);
    // };
    const handleApiLoaded = (map, maps, places) => {
        setMapas(maps);
        setMapa(map);
        setLugares(places);
        if (pedido && pedido.puntoEntrega) {
            if (typeof pedido.puntoEntrega.lat !== 'undefined' && pedido.puntoEntrega.lat !== null && typeof pedido.puntoEntrega.lon !== 'undefined' && pedido.puntoEntrega.lon !== null) {
                const coords = {
                    lat: parseFloat(pedido.puntoEntrega.lat),
                    lng: parseFloat(pedido.puntoEntrega.lon)
                }
                const bounds = new maps.LatLngBounds();
                bounds.extend(new maps.LatLng(coords.lat, coords.lng - 0.005562184202671051,));
                bounds.extend(new maps.LatLng(coords.lat, coords.lng + 0.005562184202671051,));
                bounds.extend(new maps.LatLng(coords.lat, coords.lng,));
                map.fitBounds(bounds);
            }
        }

    }
    return (
        <div id="crear-pedido">

            {pedido ? <div className="w3-padding bs-bbox w3-white">
                <div className="w3-col l12 w3-border w3-padding-small bs-bbox">
                    <b>Datos facturación</b>
                    {pedido.datosFacturacion ? <div className="w3-row">
                        {pedido.datosFacturacion.razonSocial ? <div className="w3-col l6 w3-padding-small bs-bbox">
                            <TextField fullWidth margin="normal" size="small" variant="outlined"
                                id="input-razonSocial"
                                label="Razón social"
                                type="text"
                                name="razonSocial"
                                value={pedido.datosFacturacion.razonSocial} />
                        </div> : null}
                        {pedido.datosFacturacion.ruc ? <div className="w3-col l6 w3-padding-small bs-bbox">
                            <TextField fullWidth margin="normal" size="small" variant="outlined"
                                id="input-ruc"
                                label="RUC"
                                type="text"
                                name="ruc"
                                value={pedido.datosFacturacion.ruc} />
                        </div> : null}
                    </div> : null}
                </div>
                {pedido.items ? <div className="w3-block w3-border w3-padding-small bs-bbox">
                    <b>Productos</b>
                    <table className="w3-table w3-border w3-bordered mi-tabla">
                        <thead className="w3-border">
                            <tr>
                                <th></th>
                                <th>Producto</th>
                                <th>Precio</th>
                                <th>Cantidad</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody className="w3-border">
                            {pedido.items.map((item, index) => <tr key={item.id}>
                                <td>
                                    {item.multimedia ? <div className="thumb-container"
                                        style={{
                                            backgroundImage: "url('" + Api.route(item.multimedia[0].url) + "')"
                                        }}>
                                    </div> : null}
                                </td>
                                <td>{item.nombre}</td>
                                <td>{'₲ ' + Helper.numberUi(item.monto)}</td>
                                <td> {item.cantidad}</td>
                                <td>{'₲ ' + Helper.numberUi(item.cantidad * item.monto)}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div> : null}






                {pedido.items && pedido.items.length > 0 ? <table className="w3-table w3-border w3-bordered">
                    <tbody className="w3-border">
                        <tr>
                            <td className="w3-padding">
                                <h3>Subtotal</h3>
                            </td>
                            <td className="w3-padding">
                                <h3 style={{ textAlign: 'right' }}>{moneda} {Helper.numberUi(subTotal)}</h3>
                            </td>
                        </tr>
                        {pedido.puntoEntrega ? <tr>
                            <td className="w3-padding">
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="gender" name="entrega" value={'delivery'}>
                                        <FormControlLabel value="delivery" control={<RadioTheme />} label="Delivery" />
                                    </RadioGroup>
                                </FormControl>
                            </td>
                            <td className="w3-padding">
                                <h4 style={{ textAlign: 'right' }}>{moneda} {Helper.numberUi(precioDelivery)} </h4>
                            </td>
                        </tr> : null}
                        {pedido.puntoEntrega ? <tr>
                            <td colSpan="2" className="w3-padding">
                                <div className="w3-row">
                                    <div className="w3-col l6" style={{ height: '260px' }}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: CONFIG.GOOGLE_MAP_KEY }}
                                            defaultCenter={center}
                                            defaultZoom={zoom}
                                            yesIWantToUseGoogleMapApiInternals
                                            onGoogleApiLoaded={({ map, maps, places }) => handleApiLoaded(map, maps, places)}
                                        >
                                            {(pedido.puntoEntrega) ? <Marker lat={pedido.puntoEntrega.lat} lng={pedido.puntoEntrega.lon} /> : null}
                                        </GoogleMapReact>
                                    </div>
                                    <div className="w3-col l6 w3-padding-small bs-bbox">
                                        {pedido.puntoEntrega.direccion ? <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                            name="direccion"
                                            type="text"
                                            label="Dirección"
                                            value={pedido.puntoEntrega.direccion} /> : null}<br />
                                        {(pedido.puntoEntrega.barrio && pedido.puntoEntrega.barrio.nombre) ? <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                            name="barrio"
                                            type="text"
                                            label="Barrio"
                                            value={pedido.puntoEntrega.barrio.nombre} /> : null}<br />
                                        {(pedido.puntoEntrega.ciudad && pedido.puntoEntrega.ciudad.nombre) ? <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                            name="ciudad"
                                            type="text"
                                            label="Ciudad"
                                            value={pedido.puntoEntrega.ciudad.nombre} /> : null}<br />
                                        {pedido.puntoEntrega.observaciones ? <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                            name="observaciones"
                                            type="text"
                                            label="Observaciones"
                                            value={pedido.puntoEntrega.observaciones} /> : null}<br />
                                    </div>
                                </div>
                            </td>
                        </tr> : null}
                        <tr>
                            <td className="w3-padding">
                                <h3>Total</h3>
                            </td>
                            <td className="w3-padding">
                                <h2 style={{ textAlign: 'right', color: Colors.THEME }}>{moneda} {Helper.numberUi(total)}</h2>
                            </td>
                        </tr>
                    </tbody>
                </table> : null}
                <Divider />
                <div className="w3-row w3-white">
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                            type="button"
                            onClick={() => props.cancelar()}>Cerrar</button>
                    </div>
                </div>
            </div> : null}
        </div>
    );
};
export default withRouter(VerPedido);