// Initial State
const perfilInitialState = {
    perfilData: {}
};
// Reducers (Modifies The State And Returns A New State)
const perfilReducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            return {
                ...perfilInitialState
            };
        }
        case 'PERFIL_DATA': {
            return {
                ...state,
                perfilData: action.perfilData,
            }
        }
        default: {
            return state;
        }
    }
};
// Exports
export { perfilReducer, perfilInitialState };